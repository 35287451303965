import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'src/utils/toast';

import { convertObjectToQuerystring } from 'src/utils/helper';
import {
  clickedEmailList,
  contactAdvanceFilters,
  contactAdvanceSearchFilterPayload,
  customLookupsOptions,
  openedEmailList, setValueFromStore,
} from '../../utils/contactUtils';
import {
  fetchSequenceList,
  fetchLocation,
  fetchAddress,
  fetchJobs,
  fetchContacts,
  clearContactFilters,
} from 'src/modules/contacts/actions/contactActions';
import { fetchCentralLookups } from 'src/modules/admin/actions/adminActions';

const contactContext = createContext({});

const initialState = {
  locationList: [],
  employerList: [],
  jobList: [],
  seqEnrolledList: [],
  hiringSkillsList: [],
  candidateSkillsList: [],
};

const makeDefaultHide = ['hiringSkillsList', 'candidateSkillsList'];
const initialPaginationState = {
  _from: 0,
  _size: 50,
  total: 0,
  scrollPosition: 0,
};

export const useContactFilterStore = () => {
  const {
    options,
    loading,
    fetchOption,
    updateOptionFilterData,
    handleContactFilters,
    setHandleContactFilters,
    updateFilterValue,
    onClearFilters,
  } = useContext(contactContext);
  return {
    options,
    loading,
    fetchOption,
    updateOptionFilterData,
    handleContactFilters,
    setHandleContactFilters,
    updateFilterValue,
    onClearFilters,
  };
};

export const ContactGridProvider = ({ children }) => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state?.contacts?.contacts);
  const { data } = useSelector((state) => state?.fields?.lookups);

  const [loading, setLoading] = useState({
    locationList: false,
    employerList: false,
    jobList: false,
  });

  const [options, setOptions] = useState(() => {
    const initialOptions = {};
    Object.keys(initialState).forEach((key) => {
      initialOptions[key] = {
        data: [],
        isHidden: makeDefaultHide.includes(key),
        search: '',
        pagination: { ...initialPaginationState },
      };
    });
    initialOptions.clickedEmailList = {
      data: clickedEmailList,
      search: '',
      pagination: { ...initialPaginationState },
    };
    initialOptions.openedEmailList = {
      data: openedEmailList,
      search: '',
      pagination: { ...initialPaginationState },
    };
    return initialOptions;
  });
  const [handleContactFilters, setHandleContactFilters] = useState({
    open: false,
    filters: {},
  });

  useEffect(() => {
    setHandleContactFilters({ open: false, filters: {...setValueFromStore(contacts?.filters)} });
  }, []);

  useEffect(() => {
    getContactData();
  }, [handleContactFilters?.filters]);

  useEffect(() => {
    if (data?.length) {
      const hiringSkillsOptions = data?.find(
        (item) => item?.name?.toLowerCase() === (customLookupsOptions?.hiringSkills).toLowerCase(),
      );
      const candidateSkillsOptions = data?.find(
        (item) =>
          item?.name?.toLowerCase() === (customLookupsOptions?.candidateSkills).toLowerCase(),
      );
      const tempOption = { ...options };
      if (hiringSkillsOptions?.name) {
        tempOption.hiringSkillsList.data = hiringSkillsOptions?.options || [];
      } else {
        tempOption.hiringSkillsList.isHidden = true;
      }
      if (candidateSkillsOptions?.name) {
        tempOption.candidateSkillsList.data = candidateSkillsOptions?.options || [];
      } else {
        tempOption.candidateSkillsList.isHidden = true;
      }
      setOptions(tempOption);
    }
    setLoading((prevState) => ({
      ...prevState,
      hiringSkillsList: false,
      candidateSkillsList: false,
    }));
  }, [data]);

  // useEffect(() => {
  //   // handleContactFilters?.open && fetchOptions();
  // }, [handleContactFilters?.open]);
  console.log('handleContactFilters?.filters',handleContactFilters?.filters);

  const updateFilterValue = useCallback(
    ({ key, value, valueKey = 'value', type, scrollPosition, optionKey }) => {
      const updateFilter = JSON.parse(JSON.stringify(handleContactFilters?.filters));
      if (type && type === 'calendar') {
        updateFilter[key][valueKey] = value;
      } else {
        const { selectedList, value: updatedValue } = value;
        updateFilter[key][valueKey] = updatedValue;
        updateFilter[key].selectedList = selectedList || [];
        if (type && type === 'select' && optionKey) {
          const updateOptions = { ...options };
          updateOptions[optionKey].pagination.scrollPosition = scrollPosition;
          setOptions({ ...updateOptions });
        }
      }
      setHandleContactFilters((prevState) => ({ ...prevState, filters: updateFilter }));
    },
    [handleContactFilters?.filters],
  );

  const getContactData = async () => {
    const { paging, filters, sort } = contacts;
    const updateFiltersValue = {
      ...filters,
      ...contactAdvanceSearchFilterPayload(handleContactFilters),
    };
    await dispatch(fetchContacts(paging, updateFiltersValue, sort));
  };

  const fetchOptions = () => {
    fetchJobList({});
    fetchEmployerList({});
    fetchLocationList({});
    fetchSequenceOptionsList({});
    setLoading((prevState) => ({
      ...prevState,
      hiringSkillsList: true,
      candidateSkillsList: true,
    }));
    fetchCentralLookups();
  };

  const updateOptionFilterData = ({ optionKey, key, value, scrollPosition, isSearch }) => {
    const updateOptions = { ...options };
    if (isSearch) {
      updateOptions[optionKey].search = value;
      if (!value) {
        updateOptions[optionKey].data = [];
        updateOptions[optionKey].pagination.total;
      }
    } else {
      updateOptions[optionKey].pagination[key] = value;
    }
    updateOptions[optionKey].pagination.scrollPosition = scrollPosition;
    setOptions({ ...updateOptions });
    if (!isSearch || (isSearch && value)) {
      const fetchData = fetchOption[optionKey];
      fetchData({ [key]: value });
    }
  };

  const fetchLocationList = async ({ _from = 0, _size = 50, search = '' }) => {
    try {
      const query = convertObjectToQuerystring({
        _from,
        _size,
        _search: search,
      });
      setLoading((prevState) => ({
        ...prevState,
        locationList: true,
      }));
      const response = new Promise((resolve, reject) => {
        dispatch(fetchLocation(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          options.locationList.data = search
            ? resolvedValue?.data
            : [...options.locationList.data, ...resolvedValue?.data];
          options.locationList.pagination.total = resolvedValue?.total?.value || [];
          setOptions(options);
          setLoading((prevState) => ({
            ...prevState,
            locationList: false,
          }));
        })
        .catch((error) => {
          setLoading((prevState) => ({
            ...prevState,
            locationList: false,
          }));
          toast.error(error?.error?.message || 'Fetching Employer Failed.');
        });
    } catch (error) {
      setLoading((prevState) => ({
        ...prevState,
        employerList: false,
      }));
      toast.error(error?.error?.message || 'Fetching Employer Failed.');
    }
  };

  const fetchEmployerList = async ({ _from = 0, _size = 50, search = '' }) => {
    const query = convertObjectToQuerystring({
      _from,
      _size,
      _search: search,
    });
    setLoading((prevState) => ({
      ...prevState,
      employerList: true,
    }));
    try {
      const response = new Promise((resolve, reject) => {
        dispatch(fetchAddress(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          options.employerList.data = search
            ? resolvedValue?.data
            : [...options.employerList.data, ...resolvedValue?.data];
          options.employerList.pagination.total = resolvedValue?.total?.value || [];
          setOptions(options);
          setLoading((prevState) => ({
            ...prevState,
            employerList: false,
          }));
        })
        .catch((error) => {
          setLoading((prevState) => ({
            ...prevState,
            employerList: false,
          }));
          toast.error(error?.error?.message || 'Fetching Employer Failed.');
        });
    } catch (error) {
      setLoading((prevState) => ({
        ...prevState,
        employerList: false,
      }));
      toast.error(error?.error?.message || 'Fetching Employer Failed.');
    }
  };

  const fetchJobList = async ({ _from = 0, _size = 50, search = '' }) => {
    const query = convertObjectToQuerystring({
      _from,
      _size,
      _search: search,
    });
    setLoading((prevState) => ({
      ...prevState,
      jobList: true,
    }));
    try {
      const response = new Promise((resolve, reject) => {
        dispatch(fetchJobs(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          options.jobList.data = search
            ? resolvedValue?.data
            : [...options.jobList.data, ...resolvedValue?.data];
          options.jobList.pagination.total = resolvedValue?.total?.value || [];
          setOptions(options);
          setLoading((prevState) => ({
            ...prevState,
            jobList: false,
          }));
        })
        .catch((error) => {
          setLoading((prevState) => ({
            ...prevState,
            jobList: false,
          }));
          toast.error(error?.error?.message || 'Fetching Jobs Failed.');
        });
    } catch (error) {
      setLoading((prevState) => ({
        ...prevState,
        jobList: false,
      }));
      toast.error(error?.error?.message || 'Fetching Jobs Failed.');
    }
  };

  const fetchSequenceOptionsList = async ({ _from = 0, _size = 50, search = '' }) => {
    const query = convertObjectToQuerystring({
      _from,
      _size,
      _search: search,
    });
    setLoading((prevState) => ({
      ...prevState,
      seqEnrolledList: true,
    }));
    try {
      const response = new Promise((resolve, reject) => {
        dispatch(fetchSequenceList(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          if (resolvedValue?.sequences?.length > 0) {
            options.seqEnrolledList.data = search
              ? resolvedValue?.sequences
              : [...options.seqEnrolledList.data, ...resolvedValue?.sequences];
          }
          options.seqEnrolledList.pagination.total = resolvedValue?.total?.value || [];
          setOptions(options);
          setLoading((prevState) => ({
            ...prevState,
            seqEnrolledList: false,
          }));
        })
        .catch((error) => {
          setLoading((prevState) => ({
            ...prevState,
            seqEnrolledList: false,
          }));
          toast.error(error?.error?.message || 'Fetching Enrolled by Sequence Failed.');
        });
    } catch (error) {
      setLoading((prevState) => ({
        ...prevState,
        seqEnrolledList: false,
      }));
      toast.error(error?.error?.message || 'Fetching Enrolled by Sequence Failed.');
    }
  };
  const onClearFilters = async () => {
    await dispatch(clearContactFilters());
    setHandleContactFilters((prevState) => ({
      open: prevState?.open,
      filters: JSON.parse(JSON.stringify(contactAdvanceFilters)),
    }));
  };

  const fetchOption = {
    locationList: fetchLocationList,
    employerList: fetchEmployerList,
    jobList: fetchJobList,
    seqEnrolledList: fetchSequenceOptionsList,
  };

  return (
    <contactContext.Provider
      value={{
        options,
        loading,
        fetchOption,
        updateOptionFilterData,
        handleContactFilters,
        setHandleContactFilters,
        updateFilterValue,
        onClearFilters,
      }}
    >
      {children}
    </contactContext.Provider>
  );
};

export const withContactGridProvider = (Component) => (props) =>
  (
    <ContactGridProvider>
      <Component {...props} />
    </ContactGridProvider>
  );
