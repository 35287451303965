import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallIcon from '@mui/icons-material/Call';
import SmsIcon from '@mui/icons-material/Sms';
import TaskIcon from '@mui/icons-material/Task';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import BadgeIcon from '@mui/icons-material/Badge';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { sequenceEnrollmentStatus, countryCodes } from '../config';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { Stack } from '@mui/material';
import moment from 'moment';

export const getSequenceStatus = (status) => {
  switch (status) {
    case 'never sequenced':
      status = 'Never been sequenced ';
      break;
    case 'never':
      status = 'Never been sequenced ';
      break;
    case 'paused':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Paused <PauseRoundedIcon />
        </Stack>
      );
      break;
    case 'notInterested':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Completed <CloseRoundedIcon />
        </Stack>
      );
      break;
    case 'interested':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Completed <DoneRoundedIcon />
        </Stack>
      );
      break;
    case 'completed':
      status = 'Completed ';
      break;
    case 'hold':
      status = 'On hold';
      break;
    case 'inProgress':
      status = 'In Progress';
      break;
    case 'replied':
      status = 'Replied';
      break;
    case 'draft':
      status = 'Draft';
      break;
    case 'failed':
      status = 'Failed';
      break;
    case 'unsubscribed':
      status = 'Unsubscribed';
      break;
    case 'error':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Error <CircleRoundedIcon />
        </Stack>
      );
      break;
    case 'bounced':
      status = (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'3px'}
          sx={{ svg: { fontSize: '12px' } }}
        >
          Bounced <SquareRoundedIcon />
        </Stack>
      );
      break;
  }

  return status;
};

export const getSequenceStatusColorCode = (status) => {
  let colorCode = {
    color: 'white',
    background: '#dddddd',
  };

  switch (status) {
    case 'Never Sequenced':
      colorCode.color = '#f4f4f4';
      colorCode.background = '#626262';
      break;
    case 'never':
      colorCode.color = '#f4f4f4';
      colorCode.background = '#626262';
      break;
    case 'notInterested':
      colorCode.color = '#1976d2';
      colorCode.background = '#e3effa';
      break;
    case 'interested':
      colorCode.color = '#1976d2';
      colorCode.background = '#e3effa';
      break;
    case 'completed':
      colorCode.color = '#1976d2';
      colorCode.background = '#e3effa';
      break;
    case 'paused':
      colorCode.color = '#00695c';
      colorCode.background = '#e0f2f1';
      break;
    case 'hold':
      colorCode.color = '#a53cb7';
      colorCode.background = '#f3e5f6';
      break;
    case 'inProgress':
      colorCode.color = '#2e7d32';
      colorCode.background = '#e6efe6';
      break;
    case 'replied':
      colorCode.color = '#00838f';
      colorCode.background = '#dff6f9';
      break;
    case 'draft':
      colorCode.color = '#e91e63';
      colorCode.background = '#fce4ec';
      break;
    case 'failed':
      colorCode.color = '#ef5350';
      colorCode.background = '#fae6e6';
      break;
    case 'unsubscribed':
      colorCode.color = '#ffffff';
      colorCode.background = '#F3AE59';
      break;
    case 'error':
      colorCode.color = '#ef5350';
      colorCode.background = '#fae6e6';
      break;
    case 'bounced':
      colorCode.color = '#ef6c00';
      colorCode.background = '#fff3e0';
      break;
  }
  return colorCode;
};

export const capitalizeName = (name) => {
  return name?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const getContactName = (contact) => {
  const name = `${contact?.fname} ${contact?.lname}`;
  return capitalizeName(name);
};

export const getActivityTypeIcon = (type, fontSize = '1.5rem') => {
  let icon = '';
  switch (type) {
    case 'CONTACT_ADDED':
      icon = <PersonAddIcon style={{ color: '#3f51b5' }} />;
      break;

    case 'CONTACT_UPDATED':
      icon = <SaveAsIcon style={{ color: '#3f51b5' }} />;
      break;

    case 'Enrolled_In_Sequence':
      icon = <BadgeIcon style={{ color: '#795548' }} />;
      break;

    case 'EMAIL_UPDATED':
      icon = <MarkEmailUnreadIcon style={{ color: '#FFA726' }} />;
      break;

    case 'selectEmailTemplate':
      icon = <ContactMailIcon style={{ color: '#FFA726' }} />;
      break;
    case 'email':
      icon = <EmailIcon style={{ fill: '#42A5F5', fontSize }} />;
      break;

    case 'linkedin':
    case 'linkedinConnection':
    case 'linkedinMessage':
    case 'linkedinMail':
    case 'linkedinViewProfile':
      icon = <LinkedInIcon style={{ fill: '#1282B2', fontSize }} />;
      break;
    case 'call':
      icon = <CallIcon style={{ fill: '#66BB6A', fontSize }} />;
      break;
    case 'sms':
      icon = <SmsIcon style={{ fill: '#00bcd4', fontSize }} />;
      break;
    case 'general':
    default:
      icon = <TaskIcon style={{ fill: '#3f51b5', fontSize }} />;
      break;
  }
  return icon;
};

export const getFilterLabel = (text = '', data) => {
  let newLabel = {};
  switch (text) {
    case 'users': {
      newLabel = {
        type: 'users',
        title: 'Enrolled by',
        isSingle: true,
        showName: true,
      };
      break;
    }
    // case 'created_by': {
    //   newLabel = {
    //     type: 'created_by',
    //     title: 'Created by',
    //   };
    //   break;
    // }
    // case 'type': {
    //   newLabel = {
    //     type: 'type',
    //     title: 'Type',
    //   };
    //   break;
    // }
    //
    // case 'access_level': {
    //   newLabel = {
    //     type: 'access_level',
    //     title: 'Access level',
    //   };
    //   break;
    // }
    // case 'in_progress': {
    //   newLabel = {
    //     type: 'in_progress',
    //     title: 'In Progress',
    //   };
    //   break;
    // }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

export const parseContactFilterData = (data, tab) => {
  let filterListData = Object.keys(data);
  filterListData = filterListData
    .filter((e) => e !== 'complete_date' && e !== 'enrolled_by' && e !== 'enrolled_date')
    .map((k) => ({
      name: k,
      data: data[k],
      optionLabel: 'name',
      optionValue: 'id',
      ...getFilterLabel(k, data),
    }));

  // if (tab?.name != 'In Progress' || tab.id == 9 || tab.id == 6) {
  //   filterListData.push({
  //     name: 'completeDate',
  //     control: 'dateRangePicker',
  //     isSingle: true,
  //     title: 'Completed Date',
  //     type: 'completeDate',
  //     from: 'completeFrom',
  //     to: 'completeTo',
  //   });
  // }
  return filterListData;
};

export const getSequenceTip = (sequenceEnrollment) => {
  let sequenceTip = '';
  if (sequenceEnrollment && sequenceEnrollment.length > 0) {
    switch (sequenceEnrollment[0].status) {
      case sequenceEnrollmentStatus.IN_PROGRESS:
        sequenceTip = `Contact enrolled in sequence - ${sequenceEnrollment[0]?.sequence.name}`;
        break;
      case sequenceEnrollmentStatus.COMPLETED:
        sequenceTip = `Contact has completed sequence - ${sequenceEnrollment[0]?.sequence.name}`;
        break;
      case sequenceEnrollmentStatus.HOLD:
        sequenceTip = `Contact sequence - ${sequenceEnrollment[0]?.sequence.name} has been put on hold`;
        break;
      case sequenceEnrollmentStatus.BOUNCED:
        sequenceTip = `An email has bounced for this contact. Please update email ID.`;
        break;
      case sequenceEnrollmentStatus.INTERESTED:
        sequenceTip = `Contact has been marked as interested`;
        break;
      case sequenceEnrollmentStatus.NOT_INTERESTED:
        sequenceTip = `Contact has been marked as not interested from sequence - ${sequenceEnrollment[0]?.sequence.name}`;
        break;
      case sequenceEnrollmentStatus.UNSUBSCRIBED:
        sequenceTip = `Contact has marked unsubscribe from sequence - ${sequenceEnrollment[0]?.sequence.name}`;
        break;
    }
  } else {
    sequenceTip = 'Contact has not been sequenced yet';
  }
  return sequenceTip;
};

export const getFormattedPhoneNumber = (phoneNumberString, countryCodeIncluded = true) => {
  if (phoneNumberString?.[0] === '+') phoneNumberString = phoneNumberString.slice(1);
  if (
    typeof phoneNumberString !== 'string' ||
    isNaN(phoneNumberString) ||
    phoneNumberString.length < 3
  )
    return { err: 'Invalid Number' };
  let countryCode = '';
  let isCountryCodeFound = false;
  let phoneNumber = '';
  let formatedPhoneNumber = '';
  if (countryCodeIncluded) {
    const rawCoutryCode = phoneNumberString.slice(0, 3);
    rawCoutryCode.split('').forEach((element) => {
      if (isCountryCodeFound) return;
      countryCode += element;
      if (Object.keys(countryCodes)?.includes(countryCode)) isCountryCodeFound = true;
    });
    if (!isCountryCodeFound) return { err: "Invalid number or country code doesn't exist" };
  }
  let countryData = countryCodes[countryCode];
  phoneNumber = phoneNumberString.substring(countryCode.length, phoneNumberString.length);
  let mask = countryData?.mask ? countryData.mask : '*** ***-****';
  formatedPhoneNumber = mask
    ?.split('*')
    ?.map((ele, index, arr) => {
      if (arr?.length === index + 1) {
        return phoneNumber?.substring(index);
      } else if (phoneNumber.length < index + 1) return;
      return ele + phoneNumber?.[index];
    })
    ?.join('');
  return {
    ...(countryCodeIncluded
      ? {
          countryCode: `+${countryCode}`,
          countryCodeName: countryData?.countryNameCode,
          formattedNoWithCC: `+${countryCode} ${formatedPhoneNumber}`,
        }
      : {}),
    phoneNumber: phoneNumber,
    formatedPhoneNumber,
  };
};

export const contactAdvanceFilters = {
  jobTitle: {
    value: [],
    selectedList: [],
  },
  employer: {
    value: [],
    selectedList: [],
  },
  currentEmployer: {
    value: [],
    selectedList: [],
  },
  location: {
    value: [],
    selectedList: [],
  },
  sequence: {
    value: [],
    selectedList: [],
  },
  seqEnrolledDate: {
    value: {},
  },
  createdDate: {
    value: {},
  },
  isAddedtoCrm: {
    value: null,
  },
  emailOpened: {
    value: [],
    selectedList: [],
  },
  emailClicked: {
    value: [],
    selectedList: [],
  },
  candidateSkills: {
    value: [],
    selectedList: [],
  },
  hiringSkills: {
    value: [],
    selectedList: [],
  },
};

export const tempAccordionList = [
  {
    name: 'jobTitle',
    type: 'accordion',
    subType: 'select',
    optionKey: 'jobList',
    id: 1,
    label: 'Current Job Title',
  },
  {
    name: 'employer',
    type: 'accordion',
    subType: 'select',
    optionKey: 'employerList',
    id: 4,
    label: 'Current Employer',
  },
  {
    name: 'location',
    type: 'accordion',
    subType: 'select',
    id: 5,
    optionKey: 'locationList',
    optionLabelKey: 'locationTitle',
    label: 'Location',
  },
  {
    name: 'sequence',
    type: 'accordion',
    subType: 'select',
    id: 6,
    optionKey: 'seqEnrolledList',
    optionLabelKey: 'name',
    label: 'Sequence Enrolled',
    optionValueKey: 'id',
  },

  {
    name: 'seqEnrolledDate',
    type: 'accordion',
    subType: 'calendar',
    id: 3,
    label: 'Sequence Enrolled Date',
  },
  {
    name: 'createdDate',
    type: 'accordion',
    subType: 'calendar',
    id: 8,
    label: 'Created Date',
  },
  {
    name: 'isAddedtoCrm',
    type: 'switch',
    subType: '',
    id: 2,
    label: 'Added to CRM',
  },
  {
    name: 'emailOpened',
    type: 'accordion',
    subType: 'select',
    id: 9,
    optionKey: 'openedEmailList',
    optionLabelKey: 'label',
    label: 'Opened Email',
    hideSearch: true,
    optionValueKey: 'value',
  },
  {
    name: 'emailClicked',
    type: 'accordion',
    subType: 'select',
    id: 10,
    optionKey: 'clickedEmailList',
    optionLabelKey: 'label',
    label: 'Clicked Email',
    hideSearch: true,
    optionValueKey: 'value',
  },
  {
    name: 'candidateSkills',
    type: 'accordion',
    subType: 'select',
    id: 11,
    optionKey: 'candidateSkillsList',
    label: 'Candidate Skills',
    optionLabelKey: 'label',
    optionValueKey: 'value',
  },
  {
    name: 'hiringSkills',
    type: 'accordion',
    subType: 'select',
    id: 12,
    optionKey: 'hiringSkillsList',
    label: 'Hiring Skills',
    optionLabelKey: 'label',
    optionValueKey: 'value',
  },
];

export const openedEmailList = [
  {
    label: 'Never Opened',
    value: '0',
  },
  {
    label: 'Opened at least once',
    value: '1',
  },
  {
    label: '1 to 10',
    value: '1to10',
  },
  {
    label: '11 to 25',
    value: '11to25',
  },
  {
    label: '26 to 50',
    value: '26to50',
  },
  {
    label: '51 plus',
    value: '51plus',
  },
];

export const clickedEmailList = [
  {
    label: 'Never Clicked',
    value: '0',
  },
  {
    label: 'Clicked at least once',
    value: '1',
  },
  {
    label: '1 to 2',
    value: '1to2',
  },
  {
    label: '3 to 5',
    value: '3to5',
  },
  {
    label: '6 to 10',
    value: '6to10',
  },
  {
    label: '10 plus',
    value: '10plus',
  },
];

export function contactAdvanceSearchFilterPayload(payload) {
  const filters = payload?.filters;
  let updatedFilters = {};
  for (let key in filters) {
    if (filters.hasOwnProperty(key)) {
      if (key === 'location') {
        updatedFilters.town =
          filters?.location?.value?.map((location) => location?.town)?.filter((item) => item) || [];
        updatedFilters.country =
          filters?.location?.value?.map((location) => location?.country)?.filter((item) => item) ||
          [];
        updatedFilters.state =
          filters?.location?.value.map((location) => location?.state)?.filter((item) => item) || [];
        updatedFilters.locationSelectedList = filters?.location?.selectedList;
      } else if (key === 'seqEnrolledDate') {
        updatedFilters.seqCreatedAt_gte = filters?.seqEnrolledDate?.value?.startDate
          ? moment(filters?.seqEnrolledDate?.value?.startDate).format('YYYY-MM-DD')
          : null;
        updatedFilters.seqCreatedAt_lte = filters?.seqEnrolledDate?.value?.endDate
          ? moment(filters?.seqEnrolledDate?.value?.endDate).format('YYYY-MM-DD')
          : null;
      } else if (key === 'createdDate') {
        updatedFilters.createdAt_gte = filters?.createdDate?.value?.startDate
          ? moment(filters?.createdDate?.value?.startDate).format('YYYY-MM-DD')
          : null;
        updatedFilters.createdAt_lte = filters?.createdDate?.value?.endDate
          ? moment(filters?.createdDate?.value?.endDate).format('YYYY-MM-DD')
          : null;
      }
      updatedFilters[key] = filters[key].value;
      updatedFilters[`${key}SelectedList`] = filters[key]?.selectedList || [];
    }
  }
  updatedFilters.isAddedtoCrm = filters?.isAddedtoCrm?.value;
  return updatedFilters;
}

export function countSelectedAdvanceFilters(payload) {
  const filters = payload?.filters;
  let count = 0;

  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      const { value } = filters[key];
      if (Array.isArray(value) && value.length > 0) {
        value.map(() => {
          count++;
        });
      } else if (
        (typeof value === 'object' && value !== null && Object.keys(value).length > 0) ||
        (typeof value === 'boolean' && value === true)
      ) {
        count++;
      }
    }
  }
  return count;
}

export function setValueFromStore(storeValue = {}) {
  const tempObj = JSON.parse(JSON.stringify(contactAdvanceFilters));
  if (Object.keys(storeValue)?.length > 0) {
    for (const key in tempObj) {
      if (storeValue.hasOwnProperty(key)) {
        if (Array.isArray(storeValue[key])) {
          if (storeValue[key].length > 0) {
            tempObj[key].value = storeValue[key];
          }
        } else if (
          typeof storeValue[key] === 'object' &&
          storeValue[key] &&
          Object.keys(storeValue[key]).length > 0
        ) {
          tempObj[key].value = storeValue[key];
        } else if (typeof storeValue[key] === 'boolean') {
          tempObj[key].value = storeValue[key];
        }
        tempObj[key].selectedList = storeValue[`${key}SelectedList`] || [];
      }
    }
  }
  return tempObj;
}

export const customLookupsOptions = {
  hiringSkills: 'Hiring Skill',
  candidateSkills: 'Candidate Skills',
};
