import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  Box,
  Chip,
  Checkbox,
  Paper,
  Typography,
  CircularProgress,
  ButtonBase,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../shared';
import { OptionEle, OptionWrapper } from './styles';

const Select = ({
  hideSearch,
  options,
  optionLabelKey,
  optionValueKey,
  filterValue,
  updateFilterValue,
  name,
  valueKey,
  optionFilterData,
  loading,
  optionKey,
  updateOptionFilterData,
  isExpanded,
}) => {
  if (optionFilterData?.isHidden) return true;
  const mainValue = Array.isArray(filterValue?.value) ? filterValue?.value : [];
  const selectedListValue = Array.isArray(filterValue?.selectedList)
    ? filterValue?.selectedList
    : [];
  const scrollingDivRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [fetchingData, setFetchingData] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState(optionFilterData?.search);

  useEffect(() => {
    if (
      scrollingDivRef &&
      scrollingDivRef.current &&
      scrollingDivRef.current.scrollTop &&
      optionFilterData?.pagination?.scrollPosition !== scrollPosition
    ) {
      scrollingDivRef.current.scrollTop = optionFilterData?.pagination?.scrollPosition || 0;
    }
  }, [optionFilterData?.pagination?.scrollPosition]);

  const getSelectedValue = (item) => {
    return optionLabelKey ? item[optionLabelKey] : item;
  };

  const checkIsSelected = (item) => {
    return optionValueKey
      ? selectedListValue?.some((selected) => selected[optionValueKey] === item[optionValueKey])
      : optionLabelKey
      ? selectedListValue?.some((selected) => selected[optionLabelKey] === item[optionLabelKey])
      : selectedListValue?.includes(item);
  };

  const removeSelectedItem = (selectedItem) => {
    const afterRemovalList = optionValueKey
      ? selectedListValue?.filter((item) => item[optionValueKey] !== selectedItem[optionValueKey])
      : optionLabelKey
      ? selectedListValue?.filter((item) => item[optionLabelKey] !== selectedItem[optionLabelKey])
      : selectedListValue?.filter((item) => item !== selectedItem);
    const afterRemovalListForMainValue = optionValueKey
      ? mainValue?.filter((item) =>
          item?.hasOwnProperty(optionValueKey)
            ? item[optionValueKey] !== selectedItem[optionValueKey]
            : item !== selectedItem[optionValueKey],
        )
      : optionLabelKey
      ? mainValue?.filter((item) =>
          item?.hasOwnProperty(optionLabelKey)
            ? item[optionLabelKey] !== selectedItem[optionLabelKey]
            : item !== selectedItem[optionLabelKey],
        )
      : mainValue?.filter((item) => item !== selectedItem);
    return { value: afterRemovalListForMainValue, selectedList: afterRemovalList };
  };

  const addSelectedItem = (selectedItem) => {
    const savingValue = optionValueKey ? selectedItem[optionValueKey] : selectedItem;
    mainValue?.push(savingValue);
    selectedListValue.push(selectedItem);
    return { value: mainValue, selectedList: selectedListValue };
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = useCallback(() => {
    const element = scrollingDivRef.current;
    if (element.scrollTop !== element.dataset.scrollTop) {
      element.dataset.scrollTop = element.scrollTop;
      setScrollPosition(element.scrollTop);

      if (!fetchingData && element.scrollHeight - element.scrollTop === element.clientHeight) {
        if (options?.length < optionFilterData?.pagination?.total) {
          setFetchingData(true);
          updateOptionFilterData?.({
            optionKey,
            key: '_from',
            value: (optionFilterData?.pagination?._from || 0) + 1,
            scrollPosition: scrollPosition || element?.scrollTop,
          });
        }
      }
    }
  }, [updateOptionFilterData, optionKey, scrollPosition]);

  useEffect(() => {
    if (!loading) {
      setFetchingData(false);
    }
  }, [loading]);

  return (
    <>
      {selectedListValue?.length ? (
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: '8px 25px 0 25px',
            m: 0,
            pb: isExpanded ? 0 : '8px',
            boxShadow: 'none',
            gap: '6px',
          }}
        >
          {(selectedListValue || [])?.map((item) => (
            <Chip
              onDelete={() =>
                updateFilterValue({
                  key: name,
                  value: removeSelectedItem(item),
                  valueKey,
                  scrollPosition,
                  optionKey,
                  type: 'select',
                })
              }
              deleteIcon={<CloseIcon style={{ color: '#1976D2' }} fontSize="small" />}
              sx={{ borderRadius: '3px', backgroundColor: '#F0F7FF', color: '#333333' }}
              label={getSelectedValue(item)}
            />
          ))}
        </Paper>
      ) : null}
      {isExpanded ? (
        <Box sx={{ padding: '8px 25px 16px' }}>
          {!hideSearch && (
            <Box>
              <Form
                initialValues={{
                  [`menu--input-${name}`]: '',
                }}
              >
                <Form.Field.InputDebounced
                  name={`menu--input`}
                  placeholder="Search..."
                  variant="outlined"
                  size="small"
                  autoFocus
                  onChange={(value) => {
                    setSearchInputValue(value);
                    updateOptionFilterData?.({
                      optionKey,
                      key: 'search',
                      value: value,
                      scrollPosition: 0,
                      isSearch: true,
                    });
                  }}
                  fullWidth
                  value={searchInputValue}
                  sx={{ mt: 0 }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : searchInputValue && searchInputValue !== '' ? (
                          <ButtonBase iconButton={true}>
                            <CloseIcon
                              sx={{ fill: 'rgba(0,0,0,0.6)' }}
                              onClick={() => {
                                setSearchInputValue('');
                                updateOptionFilterData?.({
                                  optionKey,
                                  key: 'search',
                                  value: '',
                                  scrollPosition: 0,
                                  isSearch: true,
                                });
                              }}
                            />
                          </ButtonBase>
                        ) : (
                          <SearchIcon sx={{ fill: 'rgba(0,0,0,0.6)' }} />
                        )}
                      </>
                    ),
                  }}
                />
              </Form>
            </Box>
          )}
          <OptionEle ref={scrollingDivRef} id="scolling-div" onScroll={handleScroll}>
            {(options || [])?.map((item, index) => (
              <OptionWrapper key={index}>
                <Checkbox
                  checked={checkIsSelected(item)}
                  sx={{ pl: 0 }}
                  onClick={() => {
                    checkIsSelected(item)
                      ? updateFilterValue({
                          key: name,
                          value: removeSelectedItem(item),
                          valueKey,
                          scrollPosition,
                          optionKey,
                          type: 'select',
                        })
                      : updateFilterValue({
                          key: name,
                          value: addSelectedItem(item),
                          valueKey,
                          scrollPosition,
                          optionKey,
                          type: 'select',
                        });
                  }}
                />
                <Typography color="#252627" variant="body2">
                  {optionLabelKey ? item[optionLabelKey] : item}
                </Typography>
              </OptionWrapper>
            ))}
            {/*{loading && (*/}
            {/*  <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1 }}>*/}
            {/*    <CircularProgress size={26} style={{ marginBottom: 16 }} color="secondary" />*/}
            {/*  </Box>*/}
            {/*)}*/}
          </OptionEle>
        </Box>
      ) : null}
    </>
  );
};
const areEqual = (prevProps, nextProps) => true;
export default React.memo(Select, areEqual);
