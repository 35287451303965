import React from 'react';
import { Card, CardMedia, Typography, Stack, CardHeader } from '@mui/material';
import { Button } from 'src/components/shared';
import moment from 'moment';
import { dateFormat } from 'src/config/index';
import { useHistory } from 'react-router-dom';

const SubscriptionCard = ({
  loading,
  initiatePayment,
  tenant,
  subscriptions,
  hasOneMembershipAccess,
  ...props
}) => {
  const { title, image, plans, type, recharge } = props.plan;
  const history = useHistory();
  const isSubscribed =
    type !== 'one-time'
      ? props.plan?.plans?.some((plan) => {
        return subscriptions?.subscription?.[plan?.planKey]?.id;
      })
      : subscriptions?.creditsPurchased?.[recharge]?.planId;

  // const data = subscriptions?.creditsPurchased?.[recharge];
  return (
    <Card>
      <Stack padding="20px" gap="20px">
        <CardHeader
          subheader={
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography {...{ color: 'textPrimary', fontWeight: 500, fontSize: '18px' }}>
                {title == 'LMS'
                  ? 'SuperReach Ai Learning'
                  : title == 'Roleplay Minutes'
                    ? 'Role-play Minutes'
                    : title}
              </Typography>
              {isSubscribed && type !== 'one-time' && (
                <Button color="secondary" onClick={() => history.push('/admin/users')}>
                  <Typography {...{ fontWeight: 500, fontSize: '16px' }}>Assign licence</Typography>
                </Button>
              )}
            </Stack>
          }
          sx={{ padding: 0 }}
        />

        {!isSubscribed && (
          <CardMedia>
            <img src={image} style={{ width: '100%' }} />
          </CardMedia>
        )}

        {type === 'one-time' ? (
          isSubscribed ? (
            <Stack
              gap={'16px'}
              padding={'20px'}
              borderRadius={'6px'}
              border={'1px solid rgba(0, 0, 0, 0.12)'}
            >
              <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
                <Typography variant="body1" {...{ fontWeight: 600, fontSize: '20px' }}>
                  {recharge === 'roleplay' ? 'Minutes ' : 'Credits '}
                  available
                </Typography>
                <Typography variant="body1" {...{ fontWeight: 600, fontSize: '28px' }}>
                  {recharge === 'data' ? '$' : ''}
                  {recharge === 'data'
                    ? Math.round(tenant.credits ?? 0)
                    : Math.round(tenant?.roleplayMinutes ?? 0)}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction={'row'} alignItems={'center'}>
                  <Typography {...{ color: 'rgba(0, 0, 0, 0.60)', fontSize: '16px' }}>
                    Used last 30 days:
                  </Typography>
                  &nbsp;
                  <Typography {...{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '16px' }}>
                    {recharge === 'data' ? '$' : ' '}
                    {recharge === 'data'
                      ? tenant?.creditUsedThisMonth > 0
                        ? tenant.creditUsedThisMonth.toFixed(2)
                        : 0
                      : tenant?.roleplayMinutesUsedThisMonth > 0
                        ? Math.round(tenant?.roleplayMinutesUsedThisMonth)
                        : 0}
                  </Typography>
                </Stack>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    props.toggleRechargeModal(recharge);
                  }}
                  disabled={loading}
                >
                  Buy {recharge === 'roleplay' ? 'Minutes' : 'Credits'}
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
              <Stack direction="row" gap="20px">
                <Button
                  onClick={() =>
                    window.open(
                      recharge === 'roleplay'
                        ? 'https://superreach.com/ai-roleplay/'
                        : 'https://superreach.com/data/',
                      '_blank',
                    )
                  }
                  color="secondary"
                >
                  Find out more
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    props.toggleRechargeModal(recharge);
                  }}
                  disabled={loading}
                >
                  Buy {recharge === 'roleplay' ? 'Minutes' : 'Credits'}
                </Button>
              </Stack>
            </Stack>
          )
        ) : (hasOneMembershipAccess && title.toLowerCase() === 'one membership') ||
          (isSubscribed) ? (
          plans
            .sort((a) => (a.planId.split('-').pop() === 'Monthly' ? -1 : 1))
            .map((plan) => {
              let type = plan.planId;
              type = type.split('-').pop();
              const subscription = subscriptions?.subscription?.[plan?.planKey];
              const isSubscribed = !!subscription?.id;
              const isPlanSubscribed = plans.some((plan) => {
                if (subscriptions?.subscription?.hasOwnProperty(plan.planKey)) {
                  const subscription = subscriptions?.subscription?.[plan.planKey];
                  return subscription.id;
                }
                return false;
              });
              return (
                <Stack key={`plan-item-${plan.planId}`} gap={'10px'} mb={2}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    mb={0.5}
                    alignItems="center"
                  >
                    <Typography variant="body1" {...{ fontWeight: 600, fontSize: '20px' }}>
                      {type == 'Monthly' ? type : 'Annual'} - ${plan.price}
                    </Typography>

                    {isPlanSubscribed ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          initiatePayment(plan.planId, subscription, false);
                        }}
                        loading={loading === plan.planId}
                        disabled={loading}
                      >
                        Add Licenses
                      </Button>
                    ) : (
                      <Button
                        onClick={() => window.open('https://superreach.com/book-a-demo/', '_blank')}
                        variant="contained"
                        color="secondary"
                        size="small"
                        loading={loading === plan.planId}
                        disabled={loading}
                      >
                        Speak to a specialist
                      </Button>
                    )}
                  </Stack>
                  {isSubscribed && (
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        textAlign="right"
                        sx={{ fontSize: '16px' }}
                      >
                        {subscription?.quantity || 0} Licenses purchased.
                      </Typography>
                      {subscription?.next_billing_at && (
                        <Typography
                          fontSize={'12px'}
                          variant="body2"
                          color="textSecondary"
                          textAlign="right"
                        >
                          Subscription ends:{' '}
                          {moment.unix(subscription.next_billing_at).format(dateFormat)}
                        </Typography>
                      )}
                    </Stack>
                  )}
                </Stack>
              );
            })
        ) : (
          <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
            <Stack direction="row" gap="20px">
              <Button
                onClick={() =>
                  window.open(
                    title === 'LMS'
                      ? 'https://superreach.com/training/'
                      : 'https://superreach.com/outreach/',
                    '_blank',
                  )
                }
                color="secondary"
                disabled={title.toLowerCase() !== 'one membership'}
              >
                Find out more
              </Button>

              <Button
                variant="contained"
                onClick={() => window.open('https://superreach.com/book-a-demo/', '_blank')}
                disabled={title.toLowerCase() !== 'one membership'}
                color="secondary"
              >
                Speak to a specialist
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default SubscriptionCard;
