import React, { useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Form, Button } from 'src/components/shared';
import { billingCountryList } from 'src/modules/admin/config/countries';

import { useDataProviders } from '../../utils/billingStore';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { isEmailExist } from '../../../../config';
import { fetchTenantConfig } from '../../../app/actions/appActions';

const inputProps = {
  size: 'small',
  fullWidth: true,
  sx: { margin: 0 },
  variant: 'outlined',
};

function BillingAddressForm({ customer, chargeBeeId, onClose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { updateCustomer } = useDataProviders();
  const tenant = useSelector((state) => state.app.tenant);
  let billingListOfCurrency = useSelector((state) => state.app.globals.billingListOfCurrency);

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await updateCustomer(chargeBeeId, data);
      if (!res?.isError) {
        dispatch(fetchTenantConfig());
        onClose();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        initialValues={{
          companyName: customer?.billing_address?.company || '',
          line1: customer?.billing_address?.line1 || '',
          line2: customer?.billing_address?.line2 || '',
          state: customer?.billing_address?.state || '',
          city: customer?.billing_address?.city || '',
          zip: customer?.billing_address?.zip || '',
          country: customer?.billing_address?.country || '',
          fname: customer?.billing_address?.first_name || '',
          lname: customer?.billing_address?.last_name || '',
          email: customer?.billing_address?.email || '',
          currency: tenant?.billingCurrency || '',
          phone: customer?.billing_address?.phone || '',
          vat: customer?.vat_number_prefix + customer?.vat_number || '',
        }}
        validationSchema={Yup.object().shape({
          companyName: Yup.string()
            .max(30, 'company name must be at most 30 characters')
            .required('Please enter company name.'),
          email: Yup.string()
            .email('Please enter a valid email address.')
            .test({
              message: () => 'Email already exists.',
              test: (values) => isEmailExist(values, customer?.email),
            })
            .required('Please enter email.'),
          country: Yup.string()
            .required('Please select a country'),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <Stack direction="row" gap={2}>
                <Box flex={1}>
                  <Typography color="textSecondary">Company name</Typography>
                </Box>
                <Box flex={3}>
                  <Form.Field.Input name="companyName" label="Company Name" {...inputProps} />
                </Box>
              </Stack>

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Billing Address</Typography>
                </Box>
                <Box flex={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Form.Field.Input name="line1" label="Address 1" {...inputProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Field.Input name="line2" label="Address 2" {...inputProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Field.Input name="city" label="City" {...inputProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Field.Input name="zip" label="Postcode" {...inputProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <Form.Field.Input name="state" label="State" {...inputProps} />
                    </Grid>
                    <Grid item xs={4} sx={{ "& .MuiInputLabel-formControl ": { top: "-8px" } }}>
                      <Form.Field.Select
                        options={billingCountryList}
                        optLabel="name"
                        optValue="code"
                        name="country"
                        label="Country"
                        {...inputProps}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Billing Contact</Typography>
                </Box>
                <Box flex={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Form.Field.Input name="fname" label="First Name" {...inputProps} />
                    </Grid>

                    <Grid item xs={4}>
                      <Form.Field.Input name="lname" label="Last Name" {...inputProps} />
                    </Grid>

                    <Grid item xs={4}>
                      <Box
                        sx={{
                          '& .react-tel-input': {
                            height: '40px !important',
                            marginTop: '0px !important',
                            '& .form-control': {
                              height: '40px !important',
                            },
                            '& .selected-flag': {
                              height: '40px !important',
                            },
                          },
                        }}
                      >
                        <Form.Field.Phone name="phone" label="Phone" {...inputProps} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Email</Typography>
                </Box>
                <Box flex={3}>
                  <Form.Field.Input name="email" label="Email" {...inputProps} />
                </Box>
              </Stack>

              {/* <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Other Billing Email</Typography>
                </Box>
                <Box flex={3}>
                  <Form.Field.Input
                    name="otherBilling"
                    label="Other billing email"
                    {...inputProps}
                  />
                </Box>
              </Stack> */}

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}>
                  <Typography color="textSecondary">Currency</Typography>
                </Box>
                <Box flex={3} sx={{ "& .MuiInputLabel-formControl ": { top: "-8px" } }}>
                  <Form.Field.Select
                    options={billingListOfCurrency}
                    showNone={false}
                    optLabel="name"
                    optValue="currencyCode"
                    name="currency"
                    label="Currency"
                    {...inputProps}
                  />
                </Box>
              </Stack>

              {values?.country === 'GB' ? (
                <Stack direction="row" gap={2} mt={2.4}>
                  <Box flex={1}>
                    <Typography color="textSecondary">VAT</Typography>
                  </Box>
                  <Box flex={3}>
                    <Form.Field.Input name="vat" label="#VAT" {...inputProps} />
                  </Box>
                </Stack>
              ) : null}

              <Stack direction="row" gap={2} mt={2.4}>
                <Box flex={1}></Box>

                <Stack flex={3} direction="row" marginLeft={'auto'}>
                  <Stack direction="row" marginLeft={'auto'} gap={2}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="medium"
                      disabled={loading}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      size="medium"
                      loading={loading}
                      disabled={loading}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default BillingAddressForm;
