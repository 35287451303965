import React, { useEffect, useState, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import api from 'src/api';
import { Grid, Box, Stack, Typography, ButtonBase, Alert } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import {
  fetchTeamsLookup,
  fetchOfficesLookup,
  fetchTimezoneLookup,
} from 'src/modules/admin/api/adminApi';
import StepperComponent from 'src/components/shared/Stepper';
import UserSubscriptionsForm from './UserSubscriptionsForm';
import toast from 'src/utils/toast';
import { permissionLevels } from './config';
import { useSelector } from 'react-redux';
import { fetchAllSubscriptions } from 'src/modules/admin/api/billingApi';
import EditIcon from '@mui/icons-material/Edit';
import { capitalizeFirstLetter } from 'src/utils/helper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { planSortOrder } from '../../containers/users/UsersView';

// outreachMonthly: licencedValues?.outreachType === 'outreachMonthly' ?? false,
//     outreachAnnual: licencedValues?.outreachType === 'outreachAnnual' ?? false,
//     lmsmonthly: licencedValues?.lmsType === 'lmsmonthly' ?? false,
//     lmsannual: licencedValues?.lmsType === 'lmsannual' ?? false,
const subcriptionListObj = {
  'LMS-Annual-upfront-GBP-Yearly': ['lmsannual'],
  'LMS-12-monthly-payments-GBP-Monthly': ['lmsmonthly'],
  'Outreach-GBP-Monthly': ['outreachMonthly'],
  'OutreachAnnual-GBP-Yearly': ['outreachAnnual'],
  'One-Membership-GBP-Yearly': ['lmsannual', 'outreachAnnual'],
  'One-Membership-Monthly-GBP-Monthly': ['outreachMonthly', 'lmsmonthly'],
};
// {
//     "LMS-Annual-upfront-GBP-Yearly": {},
//     "LMS-12-monthly-payments-GBP-Monthly": {},
//     "Outreach-GBP-Monthly": {},
//     "OutreachAnnual-GBP-Yearly": {},
//     "One-Membership-GBP-Yearly": {},
//     "One-Membership-Monthly-GBP-Monthly": { }
// }
function UserForm({
  drawerToggle,
  editUser,
  formData,
  total,
  loading,
  userData,
  plansData,
  openSubscriptionPage,
  ...props
}) {
  const [defaultCandidate, setDefaultCandidate] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [userEditPopover, setUserEditPopover] = useState(false);
  const [directSubscription, setDirectSubscription] = useState(false);
  const [plans, setPlans] = useState([]);
  const tenant = useSelector((state) => state.app.tenant);
  const globalConfig = useSelector((state) => state.app.globals);
  const user = useSelector((state) => state.auth.user);

  const [step, setStep] = useState(openSubscriptionPage);
  const [licencedValues, setLicencedValues] = useState({
    outreachType: editUser?.outreachType ?? '',
    lmsType: editUser?.lmsType ?? '',
    inviteEmail: editUser?.inviteEmail ?? false,
    lmsCategories: editUser?.lmsCategories ?? {
      sales: false,
      recruiting: false,
      leadership: false,
    },
  });

  useEffect(() => {
    if (editUser?.id === user?.id && editUser?.tenants && editUser?.tenants?.length > 0) {
      const subscriptionDetailObj = editUser?.tenants[0]?.subscription;
      let outreachType,
        lmsType = '';
      Object.keys(subscriptionDetailObj).forEach((key) => {
        if (subscriptionDetailObj[key]?.status === 'active') {
          if (subcriptionListObj.hasOwnProperty(key)) {
            subcriptionListObj[key].forEach((ele) => {
              if (ele === 'outreachMonthly' || ele === 'outreachAnnual') {
                outreachType = ele;
              } else if (ele === 'lmsmonthly' || ele === 'lmsannual') {
                lmsType = ele;
              }
            });
          }
        }
      });
      setLicencedValues((prevState) => ({
        ...prevState,
        outreachType,
        lmsType,
      }));
    }
  }, [editUser]);

  useEffect(() => {
    const fetchData = async () => {
      const timezoneOptions = await searchCandidates('', 'timezone', total);
      if (timezoneOptions?.length > 0) setTimezoneOptions(timezoneOptions);
      fetchAllSubscriptions()
        .then((res) => {
          setPlans(res.plans);
        })
        .catch((err) => {
          setPlans([]);
        });
    };
    fetchData();
    setUserEditPopover(!!editUser?.id && openSubscriptionPage === 0);
  }, []);
  const formRef = useRef();
  const editMode = editUser?.id;

  const titleList = useMemo(() => {
    return props?.globals?.titlesList || [];
  }, [props.globals]);

  useEffect(() => {
    if (editMode) {
      setDefaultCandidate(editUser);
    }
  }, [editUser]);

  const searchCandidates = async (search, lookupType, total) => {
    let res = [];
    if (lookupType === 'team') {
      res = await fetchTeamsLookup(search, total);
    } else if (lookupType === 'office') {
      res = await fetchOfficesLookup(search, total);
    } else if (lookupType === 'timezone') {
      res = await fetchTimezoneLookup(search);
    }
    return res || [];
  };

  const handleSubmit = async (values, form) => {
    setSubmitLoading(true);
    const data = {
      ...values,
      status: ['lmsType', 'outreachType'].some((key) => !!licencedValues[key])
        ? 'active'
        : 'inactive',
      outreachMonthly: licencedValues?.outreachType === 'outreachMonthly' ?? false,
      outreachAnnual: licencedValues?.outreachType === 'outreachAnnual' ?? false,
      lmsmonthly: licencedValues?.lmsType === 'lmsmonthly' ?? false,
      lmsannual: licencedValues?.lmsType === 'lmsannual' ?? false,
      ...licencedValues,
    };

    if (values.team.id) {
      data.team = values.team.id ? [values.team.id] : [];
    } else {
      delete data.team;
    }
    if (values.office.id) {
      data.office = values.office.id ? [values.office.id] : [];
    } else {
      delete data.office;
    }
    data.timezone = values?.timezone?.value || 'UGT';

    data.isAdmin = values.accessLevel === 'admin' ? true : false;
    data.acl = { accessLevel: values.accessLevel || '' };
    data.permission = values.accessLevel || '';
    delete data.accessLevel;
    try {
      if (editMode) {
        const updatedRow = { id: editUser?.id, data: data };
        await props.putUser(updatedRow);
      } else {
        await props.saveUser(data);
      }
    } finally {
      setSubmitLoading(false);
      setDirectSubscription(false);
      drawerToggle({ edit: null, open: false });
    }
  };

  const isSubscriptionSelected = ['lmsType', 'outreachType'].some((key) => licencedValues[key]);

  const formDetails = formRef?.current?.values ?? {};
  const editUserDetails = {
    Title: formDetails?.title ? formDetails?.title : '-',
    'First Name': formDetails?.fname ? formDetails.fname : '-',
    'Last Name': formDetails?.lname ? formDetails.lname : '-',
    Email: formDetails?.email ? formDetails.email : '-',
    'Job Title': formDetails?.jobTitle ? formDetails.jobTitle : '-',
    Team: formDetails?.team?.value ? formDetails.team.value : '-',
    Office: formDetails?.office?.value ? formDetails.office.value : '-',
    'Time Zone': formDetails?.timezone?.value ? formDetails.timezone.value : '-',
    'Permission Level:': formDetails?.accessLevel ? formDetails.accessLevel : '-',
  };

  const formAccess = licencedValues ?? {};
  const editUserSubscriptionDetails = {
    ...(Object.keys(plansData?.outreach?.plans ?? {})?.length
      ? {
          Outreach:
            formAccess?.outreachType === 'outreachAnnual'
              ? 'Yearly'
              : formAccess?.outreachType === 'outreachMonthly'
              ? 'Monthly'
              : '-',
        }
      : {}),
    ...(Object.keys(plansData?.lms?.plans ?? {})?.length
      ? {
          Learning:
            formAccess?.lmsType === 'lmsannual'
              ? 'Yearly'
              : formAccess?.lmsType === 'lmsmonthly'
              ? 'Monthly'
              : '-',
        }
      : {}),
    ...(['lmsannual', 'lmsmonthly']?.includes(formAccess?.lmsType)
      ? {
          'Learning Content:': Object.keys(formAccess?.lmsCategories ?? {})?.reduce(
            (acc, category) => {
              if (formAccess?.lmsCategories[category]) {
                if (acc === '-') acc = '';
                acc += `${acc && ','} ${capitalizeFirstLetter(category)}`;
              }
              return acc;
            },
            '-',
          ),
        }
      : {}),
  };

  return (
    <Form
      initialValues={{
        title: defaultCandidate?.title ?? '',
        fname: defaultCandidate.fname ?? '',
        lname: defaultCandidate.lname ?? '',
        email: defaultCandidate.email ?? '',
        status: defaultCandidate.status ?? 'active',
        jobTitle: defaultCandidate.jobTitle ?? '',
        timezone: defaultCandidate?.timezone
          ? timezoneOptions?.find((ele) => ele.value === defaultCandidate?.timezone)
          : tenant?.timezone
          ? timezoneOptions?.find((ele) => ele.value === tenant?.timezone)
          : {},
        accessLevel: defaultCandidate?.acl?.accessLevel ?? '',
        team: defaultCandidate?.team
          ? {
              id: defaultCandidate?.team && defaultCandidate?.team[0]?.id,
              value: defaultCandidate?.team && defaultCandidate?.team[0]?.name,
            }
          : {},
        office: defaultCandidate?.office
          ? {
              id: defaultCandidate?.office && defaultCandidate?.office[0]?.id,
              value: defaultCandidate?.office && defaultCandidate?.office[0]?.name,
            }
          : {},

        taskNotifications: defaultCandidate?.taskNotifications ?? false,
      }}
      validationSchema={
        directSubscription
          ? Yup.object().shape({})
          : Yup.object().shape({
              email: Yup.string()
                .email('Please enter a valid email address.')
                .test({
                  message: () => 'Email already exists.',
                  test: (values) => isEmailExist(values, defaultCandidate?.email),
                })
                .required('Please enter email.'),
              jobTitle: Yup.string().required('Please enter job title.').nullable(true),
              timezone: Yup.object()
                .required('Please choose a timezone.')
                .test({
                  message: () => 'Please choose a timezone.',
                  test: (item) => item?.value,
                }),
              fname: Yup.string()
                // .test('fname', 'First Name cannot contain special character', contactNameRegex)
                .max(64, 'first name must be at most 64 characters')
                .required('Please enter first name.'),
              lname: Yup.string()
                // .test('lname', 'Last Name cannot contain special character', contactNameRegex)
                .max(64, 'first name must be at most 64 characters')
                .required('Please enter last name.'),
            })
      }
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
      innerRef={formRef}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: 'calc(100% - 16px)' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              alignItems="flex-start"
              height="100%"
              gap={'24px'}
            >
              {!userEditPopover && (
                <Stack margin={'0 auto'} width={'70%'}>
                  <StepperComponent
                    activeStep={step}
                    onChange={async (nextStep) => {
                      if (step === 0) {
                        const errors = await formRef?.current?.validateForm();
                        if (Object.keys(errors)?.length) {
                          await formRef?.current?.submitForm();
                          toast.error('Please enter all required details properly');
                        } else setStep(nextStep);
                      } else setStep(nextStep);
                    }}
                    steps={['User details', 'Access']}
                    sx={{
                      width: '100%',
                      '& .Mui-active, & .Mui-completed': {
                        color: '#1976D2 !important',
                        '& > p': {
                          color: '#1976D2 !important',
                        },
                      },
                      '& .MuiStep-root.Mui-completed': {
                        opacity: 0.6,
                      },
                      '& .MuiStepConnector-line': {
                        backgroundColor: '#00000060 !important',
                        margin: '0 8px !important',
                      },
                      '& .MuiStepLabel-label p': {
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                      '&.MuiStepper-root': {
                        gap: '8px',
                        button: {
                          padding: '0 !important',
                        },
                        '> div': {
                          padding: 0,
                          '& > button': {
                            padding: '6px 12px',
                            margin: 0,
                            width: 'fit-content',
                          },
                        },
                      },
                    }}
                  />
                </Stack>
              )}
              {/*{!userEditPopover && step === 0 && !user?.isAdmin && (*/}
              {/*  <Stack gap={0.8} width={'100%'}>*/}
              {/*    <Typography>Licences remaining</Typography>*/}
              {/*    <Stack direction="row" gap={1.4}>*/}
              {/*      {Object.keys(plansData || {})*/}
              {/*        ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))*/}
              {/*        ?.map((subscriptionType) => {*/}
              {/*          const subscriptionPlan = plansData[subscriptionType];*/}
              {/*          let total = 0;*/}
              {/*          let available = 0;*/}
              {/*          let used = 0;*/}
              {/*          Object.values(subscriptionPlan?.plans)?.forEach((plan) => {*/}
              {/*            total = total + (plan.quantity || 0);*/}
              {/*            used = used + (plan.used || 0);*/}
              {/*          });*/}
              {/*          available += total - used;*/}
              {/*          return (*/}
              {/*            <Stack*/}
              {/*              justifyContent={'center'}*/}
              {/*              padding={'12px'}*/}
              {/*              width="calc(50% - 10px)"*/}
              {/*              border={'1px solid #00000042'}*/}
              {/*              borderRadius={'4px'}*/}
              {/*              direction={'row'}*/}
              {/*              alignItems={'center'}*/}
              {/*              gap={'7px'}*/}
              {/*            >*/}
              {/*              <Typography>{subscriptionPlan?.title}</Typography>*/}
              {/*              <span>|</span>*/}
              {/*              <Typography fontWeight={500}>*/}
              {/*                {available} of {total}*/}
              {/*              </Typography>*/}
              {/*            </Stack>*/}
              {/*          );*/}
              {/*        })}*/}
              {/*    </Stack>*/}
              {/*  </Stack>*/}
              {/*)}*/}
              {!userEditPopover && step === 0 && (
                <Box>
                  <Typography>User's Information</Typography>
                  <Form.Field.Select
                    options={titleList}
                    fullWidth
                    name="title"
                    label="Title"
                    placeholder="Title"
                    variant="outlined"
                    optLabel="label"
                    optValue="value"
                    showNone={false}
                  />
                  <Form.Field.Input
                    fullWidth
                    rows={4}
                    variant="outlined"
                    name="fname"
                    label="First Name"
                  />
                  <Form.Field.Input
                    fullWidth
                    rows={4}
                    variant="outlined"
                    name="lname"
                    label="Last Name"
                  />

                  <Form.Field.Input
                    fullWidth
                    rows={4}
                    variant="outlined"
                    name="jobTitle"
                    label="Job Title"
                  />
                  <Form.Field.Input
                    fullWidth
                    rows={4}
                    variant="outlined"
                    name="email"
                    label="Email"
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Form.Field.AutoComplete
                        options={[]}
                        fullWidth
                        name="team"
                        label="Team"
                        placeholder="Team"
                        variant="outlined"
                        remoteMethod={(val) => {
                          return searchCandidates(val, 'team', total);
                        }}
                        optLabel="value"
                        optValue="id"
                        multiple={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Form.Field.AutoComplete
                        options={[]}
                        fullWidth
                        name="office"
                        label="Office"
                        placeholder="Office"
                        variant="outlined"
                        remoteMethod={(val) => {
                          return searchCandidates(val, 'office', total);
                        }}
                        optValue="id"
                        optLabel="value"
                        multiple={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Form.Field.AutoComplete
                        options={[]}
                        fullWidth
                        name="timezone"
                        label="Timezone"
                        placeholder="Timezone"
                        variant="outlined"
                        optLabel="value"
                        remoteMethod={(val) => {
                          return searchCandidates(val, 'timezone', total);
                        }}
                        optValue="id"
                        multiple={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Form.Field.Select
                        fullWidth
                        options={permissionLevels}
                        variant="outlined"
                        name="accessLevel"
                        label="Permission Level"
                        placeholder="Access Level"
                        optLabel="label"
                        optValue="value"
                        showNone={false}
                      />
                    </Grid>
                    {/* <Grid container spacing={2}>
                    <Grid item xs={6} ml={4} sx={{ display: 'flex' }}>
                      <Form.Field.Switch rows={4} variant="outlined" name="status" label="Status" />
                      <Spacer y={7} />
                      <TitleBar tooltip="Active/Inactive" />
                    </Grid>
                  </Grid> */}
                  </Grid>
                </Box>
              )}
              {!userEditPopover && step === 1 && (
                <UserSubscriptionsForm
                  userData={editUser?.id ? editUser : userData}
                  plans={plans}
                  drawerToggle={drawerToggle}
                  fetchSubscription={() =>
                    fetchAllSubscriptions()
                      .then((res) => {
                        setPlans(res.plans);
                      })
                      .catch((err) => {
                        setPlans([]);
                      })
                  }
                  resetDrawer={async () => {
                    await formRef.current?.setValues({
                      accessLevel: '',
                      email: '',
                      fname: '',
                      jobTitle: '',
                      lname: '',
                      office: {},
                      status: 'active',
                      taskNotifications: false,
                      team: {},
                      timezone: {},
                      title: '',
                    });
                    setLicencedValues({
                      outreachType: '',
                      lmsType: '',
                      inviteEmail: false,
                      lmsCategories: {
                        sales: false,
                        recruiting: false,
                        leadership: false,
                      },
                    });
                    drawerToggle({ edit: null, open: true });
                    setStep(0);
                  }}
                  plansData={plansData}
                  licencedValues={licencedValues}
                  setLicencedValues={setLicencedValues}
                />
              )}
              {userEditPopover && (
                <Stack gap={'32px'} marginTop={'16px'} height={'100%'} width={'100%'}>
                  <Stack gap={'16px'}>
                    <Stack flexDirection="row" justifyContent={'space-between'}>
                      <Typography fontSize={'20px'} fontWeight={'500'} color={'#00000099'}>
                        Details:
                      </Typography>
                      <ButtonBase
                        onClick={() => {
                          setUserEditPopover(false);
                          setStep(0);
                        }}
                      >
                        <EditIcon color={'#00000099'} />
                      </ButtonBase>
                    </Stack>
                    <Stack gap={'8px'}>
                      {Object.keys(editUserDetails)?.map((key) => {
                        return (
                          <Stack direction={'row'}>
                            <Typography flex={1} color={'#00000099'}>
                              {key}:
                            </Typography>
                            <Typography flex={1.75} color={'#00000099'} fontWeight={500}>
                              {editUserDetails[key]}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                  <Stack borderBottom={'1px solid #0000001F'} width={'100%'} />
                  <Stack gap={'16px'}>
                    <Stack flexDirection="row" justifyContent={'space-between'}>
                      <Typography fontSize={'20px'} fontWeight={'500'} color={'#00000099'}>
                        Subscription:
                      </Typography>
                      <ButtonBase
                        onClick={() => {
                          setUserEditPopover(false);
                          setDirectSubscription(true);
                          setStep(1);
                        }}
                      >
                        <EditIcon color={'#00000099'} />
                      </ButtonBase>
                    </Stack>
                    <Stack gap={'8px'}>
                      {Object.keys(editUserSubscriptionDetails)?.map((key) => {
                        return (
                          <Stack direction={'row'}>
                            <Typography flex={1} color={'#00000099'}>
                              {key}:
                            </Typography>
                            <Typography flex={1.75} color={'#00000099'} fontWeight={500}>
                              {editUserSubscriptionDetails[key] == '-' ? 'Inactive' : 'Active'}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                  <Stack flex={1} />
                  {/* {!['lmsType', 'outreachType'].some((key) => !!licencedValues[key]) && (
                    <Alert severity="warning" sx={{ '& svg': { color: '#0000008A' } }}>
                      No subscription is assigned. The user will be saved as inactive.
                    </Alert>
                  )} */}
                </Stack>
              )}
              <Box sx={{ flex: 1 }} />
              {step === 1 && (
                <Box display="flex" mt={0} justifyContent="flex-end" width="100%">
                  <Button color="secondary" variant="outlined" onClick={() => setStep(0)}>
                    Back
                  </Button>
                  <Spacer basis={2} />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading.users || submitLoading}
                    disabled={!isSubscriptionSelected}
                  >
                    {editUser?.id ? 'Update' : 'Save'}
                  </Button>
                </Box>
              )}
              {step === 0 && (
                <Box display="flex" mt={0} justifyContent="flex-end" width="100%">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => drawerToggle({ edit: null, open: false })}
                    loading={loading.users}
                  >
                    Cancel
                  </Button>
                  <Spacer basis={2} />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={async () => {
                      const errors = await formRef?.current?.validateForm();
                      if (Object.keys(errors)?.length) {
                        await formRef?.current?.submitForm();
                        toast.error('Please enter all required details');
                      } else setStep(step + 1);
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

const isEmailExist = async (values, currentEmail, loading) => {
  if (values === currentEmail) return true;
  try {
    let response = await api(`/user?email=${values}`, null, 'get');
    if (response.users.length > 0) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
};

export default UserForm;
