import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLoader from 'src/layouts/AppLoader';
import { loginWithGoogle } from '../../actions/authActions';

const GoogleAuthCallBack = ({ loginWithGoogleByTempId }) => {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const tempID = params.get('tempID');

  useEffect(() => {
    if (!tempID) {
      window.location.replace('/auth/login');
    }
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      await loginWithGoogleByTempId(tempID);
    } catch (e) {
      window.location.replace('/auth/login');
      // toast.error(typeof e === 'string' ? e : 'Something went wrong!');
    }
  };

  return (
    <>
      <AppLoader />
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loginWithGoogleByTempId: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(loginWithGoogle(data, resolve, reject));
    });
  },
});

export default connect(null, mapDispatchToProps)(GoogleAuthCallBack);
