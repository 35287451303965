import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Stack } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { useDataProviders } from '../../utils/billingStore';
import toast from 'src/utils/toast';
import { isValidArray, isValidObject, isValidObjectWithValues } from 'src/utils/validation';

import CreditRecharge from '../../components/Billing/CreditRecharge';
import SubscriptionCard from '../../components/Billing/SubscriptionCard';

const cbInstance = Chargebee.init({
  site: process.env.REACT_APP_CHARGEBEE_ID,
  isItemsModel: true,
});

export const subscriptionObject = {
  oneMembershipMonthly: 'One-Membership-Monthly-GBP-Monthly',
  oneMembershipYearly: 'One-Membership-GBP-Yearly',
  lmsMonthly: 'LMS-12-monthly-payments-GBP-Monthly',
  lmsYearly: 'LMS-Annual-upfront-GBP-Yearly',
  outreachMonthly: 'Outreach-GBP-Monthly',
  outreachYearly: 'OutreachAnnual-GBP-Yearly',
};

const outReachPlans = ['OutreachAnnual-GBP-Yearly', 'Outreach-GBP-Monthly'];
const oneMemberShipPlans = ['One-Membership-GBP-Yearly', 'One-Membership-Monthly-GBP-Monthly'];

const lmsPlans = [
  'LMS-12-monthly-payments-GBP-Monthly',
  'LMS-Annual-upfront-GBP-Yearly',
  'LMS---Annual-GBP-Yearly',
];

const extractSubscriptions = (subscriptions) => {
  if (subscriptions && subscriptions.subscription && isValidArray(subscriptions.subscription)) {
    return subscriptions.subscription.map((item) => ({
      status: item?.subscription?.status,
      planType: item?.subscription?.subscription_items[0]?.item_price_id,
    }));
  }
  return [];
};

function BillingSubscriptions() {
  const { getAllSubscriptions, getSubscriptions, allSubscriptions, subscriptions, getHostedPage } =
    useDataProviders();

  const [loading, setLoading] = useState(undefined);

  const tenant = useSelector((state) => state.app.tenant);
  const user = useSelector((state) => state.auth.user);

  const hasActivePlan = (subscriptions, plans) => {
    return subscriptions.some(
      (item) => plans.includes(item?.planType) && item?.status === 'active',
    );
  };

  const hasSubscription = (subscriptions, plans) => {
    return plans.some((item) => !!subscriptions[item]?.id);
  };

  const updatedSubscriptions = useMemo(() => extractSubscriptions(subscriptions), [subscriptions]);

  // const hasOneMembershipAccess = useMemo(() => {
  //   let hasOutReachSubscription,
  //     hasLmsSubscription = false;
  //   hasLmsSubscription = isValidArray(updatedSubscriptions)
  //     ? hasActivePlan(updatedSubscriptions, lmsPlans)
  //     : isValidObject(subscriptions.subscription) &&
  //     hasSubscription(subscriptions.subscription, lmsPlans);

  //   hasOutReachSubscription = isValidArray(updatedSubscriptions)
  //     ? hasActivePlan(updatedSubscriptions, outReachPlans)
  //     : isValidObject(subscriptions.subscription) &&
  //     hasSubscription(subscriptions.subscription, outReachPlans);

  //   const checkItHaveOneMembership = isValidArray(updatedSubscriptions)
  //     ? hasActivePlan(updatedSubscriptions, oneMemberShipPlans)
  //     : isValidObject(subscriptions.subscription) &&
  //     hasSubscription(subscriptions.subscription, oneMemberShipPlans);
  //   return checkItHaveOneMembership || (hasOutReachSubscription && hasLmsSubscription);
  // }, [user, subscriptions]);

  const hasOneMembershipAccess = true

  const hasLmsAccess = useMemo(() => {
    return isValidArray(updatedSubscriptions)
      ? hasActivePlan(updatedSubscriptions, lmsPlans)
      : isValidObject(subscriptions.subscription) &&
      hasSubscription(subscriptions.subscription, lmsPlans);
  }, [user, subscriptions, updatedSubscriptions]);

  const hasOutreachAccess = useMemo(() => {
    return isValidArray(updatedSubscriptions)
      ? hasActivePlan(updatedSubscriptions, outReachPlans)
      : isValidObject(subscriptions.subscription) &&
      hasSubscription(subscriptions.subscription, outReachPlans);
  }, [user, subscriptions, updatedSubscriptions]);

  const chargeBeeId = tenant?.chargeBeeId || '';
  const [showCreditRecharge, setShowCreditRecharge] = useState(undefined);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  useEffect(() => {
    getSubscriptions(chargeBeeId);
  }, [chargeBeeId]);

  const initiatePayment = async (planId, subscription, isCharge) => {
    try {
      setShowCreditRecharge(undefined);
      cbInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve, reject) {
            setLoading(planId);
            const quantity = subscription?.quantity || 0;
            getHostedPage(planId, subscription, isCharge, quantity)
              .then((res) => {
                setLoading(undefined);
                resolve(res);
              })
              .catch((err) => {
                setLoading(undefined);
                toast.error(err?.error?.message || 'An error ocurred! Please try again.');
              });
          });
        },
        close: function () {
          window.location.reload();
        },
      });
    } catch (error) {
      toast.error(error?.message || 'An error ocurred! Please try again.');
    }
  };

  const toggleRechargeModal = (type) => {
    if (type && type !== '') {
      setShowCreditRecharge(type);
    } else {
      setShowCreditRecharge(false);
    }
  };

  const subscriptionOrder = ['Outreach', 'LMS', 'Data Credits', 'Roleplay Minutes'];
  const userHasSubscriptions = isValidArray(updatedSubscriptions)
    ? (updatedSubscriptions || [])?.some((item) => item?.status === 'active')
    : isValidObjectWithValues(subscriptions?.subscription);

  const isOneMemberSubscribed = allSubscriptions.find(({ title }) => title === "One membership")?.plans?.some((plan) => {
    return subscriptions?.subscription?.[plan?.planKey]?.id;
  });

  return (
    <>
      <Stack sx={{ width: 'clamp(250px, 50%, 550px)', gap: '30px' }}>
        {allSubscriptions
          ?.sort((a, b) => {
            const typeA = a?.title;
            const typeB = b?.title;
            return subscriptionOrder.indexOf(typeA) - subscriptionOrder.indexOf(typeB);
          })
          ?.flatMap((plan, index) => {
            if (
              plan.type === 'one-time' &&
              plan?.recharge === 'data' &&
              !(hasOutreachAccess || isOneMemberSubscribed)
            )
              return [];
            else if (
              plan.type === 'one-time' &&
              plan?.recharge === 'roleplay' &&
              !(hasLmsAccess || isOneMemberSubscribed)
            )
              return [];
            else if (userHasSubscriptions && plan.title?.toLowerCase() === 'lms' && !hasLmsAccess)
              return [];
            else if (userHasSubscriptions && plan.title?.toLowerCase() === 'outreach' && !hasOutreachAccess)
              return [];
            else if (
              userHasSubscriptions &&
              plan.title?.toLowerCase() === 'outreach' &&
              !hasOutreachAccess
            )
              return [];
            else
              return (
                <Grid item xs={5}>
                  <SubscriptionCard
                    subscriptions={subscriptions}
                    key={`subscription-card-${index}`}
                    plan={plan}
                    hasOneMembershipAccess={hasOneMembershipAccess}
                    initiatePayment={initiatePayment}
                    loading={loading}
                    tenant={tenant}
                    toggleRechargeModal={toggleRechargeModal}
                  />
                </Grid>
              );
          })}
      </Stack>
      <Spacer x={4} y={4} />
      <CreditRecharge
        subscriptions={subscriptions}
        open={!!showCreditRecharge}
        creditRechargeType={showCreditRecharge}
        onClose={toggleRechargeModal}
        initiatePayment={initiatePayment}
        loading={loading}
      />
    </>
  );
}

export default BillingSubscriptions;
