import { all, fork, put, call, takeEvery, takeLatest, select, delay } from 'redux-saga/effects';
import * as taskTypes from '../actions/taskTypes';
import * as taskApis from '../api/taskApis';
import * as taskActions from '../actions/taskActions';
import { parseTaskFilterData } from '../utils/tasksUtils';

import {
  toggleAddTaskForm,
  setTaskCounts,
  fetchTaskCount,
} from 'src/modules/app/actions/appActions';

import toast from 'src/utils/toast';
import moment from 'moment';
import { isEmpty, template } from 'lodash';
import { push, replace } from 'connected-react-router';
import { saveTemplate } from 'src/modules/admin/api/adminApi';

import { getDraftEnrollments, getSequence } from 'src/modules/sequence/api/sequenceApi';
import { dateFormat } from 'src/config';
import { fetchContact, fetchSequenceEnrollment } from 'src/modules/contacts/actions/contactActions';
import { getRelativeDateRange } from 'src/utils/dateUtils';

const getAllTasks = (state) => state.tasks.tasks;
const getEmailMessages = (state) => state.tasks.taskReplies;
const getOutboxTasks = (state) => state.tasks.outbox;
const getTaskCurrentTab = (state) => state.tasks.currentTab;
const getTaskBoxType = (state) =>
  state.router?.location?.pathname ? state.router?.location?.pathname?.split('/').slice(-1)[0] : '';
const getCurrentTask = (state) => state.tasks.task;
const getCurrentUser = (state) => state.auth.user;
const getCurrentPath = (state) => state.router?.location?.pathname;

const getAllErrors = (state) => state.tasks.taskErrors;
const getCurrentError = (state) => state.tasks.taskError;
const getTasksQueue = (state) => state.tasks.tasksQueue;

const getShowTaskForm = (state) => state.app.showTaskForm;

const getInbox = (state) => state.tasks.inbox;
const getSent = (state) => state.tasks.sent;
const getTaskReplies = (state) => state.tasks.taskReplies;

function* fetchTaskErrors({ paging, filters }) {
  try {
    yield put(fetchTaskCount());
    const user = yield select(getCurrentUser);
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.sequence
        ? {
            sequence_eq: filters.sequence.id,
          }
        : {}),
      ...(filters?.assignedTo && filters.assignedTo?.id
        ? { createdBy_eq: filters.assignedTo?.id }
        : {}),
      ...filters,
      ...(filters?.errorType_eq ? { errorType_eq: filters?.errorType_eq.value } : {}),
      ...(filters?.stepType_eq ? { stepType_eq: filters?.stepType_eq.value } : {}),
      ...(filters?.seqStatus_eq ? { seqStatus_eq: filters?.seqStatus_eq.value } : {}),
    };
    delete filter.sequence;
    delete filter.assignedTo;

    const response = yield call(taskApis.fetchActiveTaskErrors, filter);
    yield put(taskActions.setTaskErrors(response.errors, response.total));
  } catch (error) {
    // yield put(taskActions.setTaskErrors([], { value: 0 }));
  }
}

function* fetchTasks({ paging, filters, sort, tab }) {
  try {
    const user = yield select(getCurrentUser);
    if (filters && typeof filters !== 'undefined') {
      filters = JSON.parse(JSON.stringify(filters));
    }
    const status = filters?.status?.id || 'due';
    delete filters.status;
    let today = new Date().toISOString();
    const dueDate = filters.dueDate;
    delete filters.dueDate;

    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.user && filters.user?.id
        ? { createdBy_eq: filters.user?.id }
        : { createdBy_eq: user.id }),
      ...filters,
    };

    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }

    let dueDateFilters = [];
    switch (true) {
      case status === 'due':
        dueDateFilters = ['dueOn_gte', 'dueOn_lte'];
        filter.status_neq = 'completed,error';
        break;

      case status === 'completed':
        filter.status_eq = 'completed';
        dueDateFilters = ['completedOn_gteq', 'completedOn_lteq'];
        break;
    }

    switch (tab) {
      case 'all':
        filter.taskType_neq = 'email';
        break;
      case 'email':
        filter.taskType_eq = 'selectEmailTemplate';
        break;
      case 'replies':
        filter.status_eq = 'replied';
        filter.taskType_eq = 'selectEmailTemplate,email';
        break;
      case 'linkedin':
        if (filters?.taskType_eq && !isEmpty(filters?.taskType_eq)) {
          filter.taskType_eq = filters?.taskType_eq.value;
        } else {
          filter.taskType_eq =
            'linkedin,linkedinConnection,linkedinMessage,linkedinMail,linkedinViewProfile';
        }

        break;
      case 'sms':
        filter.taskType_eq = 'sms';
        break;
      case 'call':
        filter.taskType_eq = 'call';
        break;
      case 'general':
        filter.taskType_eq = 'general';
        break;
      case 'errors':
        break;
    }

    switch (true) {
      case !dueDate:
        filter[dueDateFilters[1]] = moment().format('YYYY-MM-DD');
        break;

      case (dueDate?.name && dueDate?.id === 'today') || !dueDate:
        filter[dueDateFilters[0]] = moment().format('YYYY-MM-DD');
        filter[dueDateFilters[1]] = moment().format('YYYY-MM-DD');
        break;

      case (dueDate?.name && dueDate?.id === 'yesterday') || !dueDate:
        let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
        filter[dueDateFilters[0]] = new Date(yesterday + ' ' + '00:00').toISOString();
        filter[dueDateFilters[1]] = new Date(yesterday + ' ' + '23:59').toISOString();
        break;

      case dueDate?.name && dueDate?.id === 'tomorrow':
        let date = moment().add(1, 'days').format('YYYY-MM-DD');
        filter[dueDateFilters[0]] = new Date(date + ' ' + '00:00').toISOString();
        filter[dueDateFilters[1]] = new Date(date + ' ' + '23:59').toISOString();
        break;

      case dueDate?.name && dueDate?.id === 'lastWeek':
        filter[dueDateFilters[0]] = moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
        filter[dueDateFilters[1]] = moment()
          .subtract(1, 'weeks')
          .endOf('isoWeek')
          .format('YYYY-MM-DD');
        break;

      case dueDate?.name && dueDate?.id === 'last7days':
        filter[dueDateFilters[0]] = moment().subtract(7, 'days').toISOString();
        filter[dueDateFilters[1]] = today;
        break;

      case dueDate?.name && dueDate?.id === 'last10days':
        filter[dueDateFilters[0]] = moment().subtract(10, 'days').toISOString();
        filter[dueDateFilters[1]] = today;
        break;

      case dueDate?.name && dueDate?.id === 'next7days':
        filter[dueDateFilters[0]] = today;
        filter[dueDateFilters[1]] = moment().add(7, 'days').toISOString();
        break;

      case dueDate?.name && dueDate?.id === 'next10days':
        filter[dueDateFilters[0]] = today;
        filter[dueDateFilters[1]] = moment().add(10, 'days').toISOString();
        break;

      case dueDate?.startDate &&
        dueDate?.startDate !== '' &&
        dueDate?.endDate &&
        dueDate?.endDate !== '':
        const startDate = moment(dueDate.startDate);
        const endDate = moment(dueDate.endDate);
        if (endDate.diff(startDate, 'days') <= 1) {
          filter[dueDateFilters[0]] = startDate.format('YYYY-MM-DD');
          filter[dueDateFilters[1]] = endDate.format('YYYY-MM-DD');
        } else {
          filter[dueDateFilters[0]] = startDate.toISOString();
          filter[dueDateFilters[1]] = endDate.toISOString();
        }
        break;

      default:
        filter.scheduledOn_lte = today;
        break;
    }

    delete filter?.contact;
    delete filter?.user;
    delete filter?.createdAtRange;
    delete filter?.dueOnRange;

    const response = yield call(taskApis.fetchTasks, filter);
    yield put(taskActions.setTasks(response.tasks, response.total, sort));
  } catch (error) {
    yield put(taskActions.setTasks([], { value: 0 }));
  }
}

function* postTask({ task, resolve, reject }) {
  try {
    const res = yield call(taskApis.createTask, task);
    if (res.error?.message) {
      toast.error(res.error?.message, 'tc');
      resolve(res);
      return;
    }
    const isDrawerOpen = yield select(getShowTaskForm);
    if (isDrawerOpen === true) {
      yield put(toggleAddTaskForm());
    }

    let tasksObj = yield select(getAllTasks);
    const getCurrentPath = (state) => state.router?.location?.pathname;
    let path = yield select(getCurrentPath);
    let type = path.split('/');
    type = type[type.length - 1];
    const tab = yield select(getTaskCurrentTab);
    yield put(taskActions.fetchTasks(tasksObj.paging, tasksObj.filters, tasksObj.sort, type, tab));
    toast.success('Task added!');
    resolve(res);
  } catch (error) {
    const message = error?.error?.message
      ? error?.error?.message == 'Inbox Not Connected'
        ? 'Inbox not connected. Connecting your inbox from your profile screen is required.'
        : error?.error?.message
      : 'Error ocurred please try again!';
    toast.error(message);
    reject(error);
  }
}

function* putTask({ taskId, task, resolve, reject }) {
  try {
    let res = yield call(taskApis.updateTask, taskId, task);
    if (!res.task.sequence?.id && res?.task?.sequence) {
      const sequenceRes = yield call(getSequence, res?.task?.sequence);
      res.task.sequence = sequenceRes.sequence;
    }
    let tasksObj = yield select(getAllTasks);
    const boxType = yield select(getTaskBoxType);
    let tasks = tasksObj.data.map((task) => {
      if (task.id === taskId) return res.task;
      return task;
    });
    toast.success('Task updated!');
    yield put(taskActions.setTasks(tasks, { value: tasksObj.paging.count }));

    const getCurrentPath = (state) => state.router?.location?.pathname;
    let path = yield select(getCurrentPath);
    let type = path.split('/');
    type = type[type.length - 1];
    const tab = yield select(getTaskCurrentTab);
    if (tab === 'email' && boxType === 'inbox') {
      tasksObj.filters = {
        ...tasksObj.filters,
        status_eq: 'replied',
        taskType_eq: 'selectEmailTemplate,email',
      };
    }
    if (boxType === 'inbox' || boxType === 'outbox')
      yield put(taskActions.fetchTasks(tasksObj.paging, tasksObj.filters, {}, boxType, tab));
    else if (boxType === 'errors') {
      const { paging, filters } = yield select(getAllErrors);
      yield put(taskActions.fetchTaskErrors(paging, filters, tab));
    }

    const currentTask = yield select(getCurrentTask);
    if (currentTask?.id && currentTask.id === currentTask.id) {
      yield put(taskActions.setTask(res.task));
    }

    const isDrawerOpen = yield select(getShowTaskForm);
    if (isDrawerOpen === true) {
      yield put(toggleAddTaskForm());
    }

    resolve(res);
  } catch (error) {
    toast.error(error?.message ? error?.message : 'Error ocurred please try again!');
    reject(error);
  }
}

function* fetchTaskFilterOptions({ tab }) {
  try {
    const taskOptions = yield call(taskApis.fetchTaskOptionsFilters);
    const filterData = yield call(parseTaskFilterData, taskOptions, tab);
    yield put(taskActions.setTaskOptionsFilters(taskOptions, filterData));
  } catch (error) {}
}

function* fetchTask({ taskId }) {
  try {
    const response = yield call(taskApis.getTaskDetails, taskId);
    yield put(taskActions.setTask(response.task));
  } catch (error) {
    yield put(taskActions.setTask({}));
  }
}

function* fetchTaskReplies({ taskId, paging, filters }) {
  try {
    const filters = {
      task_eq: taskId,
    };
    const res = yield call(taskApis.fetchTaskReplies, filters);
    yield put(taskActions.setTaskReplies(res.emailMessages, res.total));
  } catch (error) {}
}

function* postTaskReply({ data, resolve, reject }) {
  try {
    let templateId = null;
    if (data?.includeTemplateData) {
      let template = yield call(saveTemplate, {
        contactId: data.contact.id,
        description: data.content,
        content: data.content,
        type: 'clonedEmail',
        currentUserId: data.sender.id,
        taskId: data.task,
        name: data?.template?.name,
        attachments: data?.attachments,
        ...(data?.scheduledOn ? { scheduledOn: data?.scheduledOn } : {}),
      });
      templateId = template.template.id;
      delete data.includeTemplateData;
    }

    let reminderOptions = {};
    const reminder = data.remind;
    if (reminder?.status === true) {
      reminderOptions = {
        remindOn: moment().add(reminder.duration, reminder.unit).unix(),
        reminderType: reminder.reminderType,
      };
    }
    delete data.remind;
    if (data?.template === '') {
      delete data.template;
    }

    let workEmail = '';
    let email = '';
    if (data?.contact?.emails?.length) {
      workEmail = data.contact.emails.filter((email) => {
        return email.isPrimary === true && email.type == 'work';
      });
      if (workEmail.length) {
        email = workEmail[0].email;
      } else {
        email = data.contact.emails[0].email;
      }
    }
    const payload = {
      ...data,
      sender: data.sender.id,
      from: data.sender.email,
      to: email,
      contact: data.contact.id,
      ...reminderOptions,
      template: templateId ? templateId : null,
    };
    const res = yield call(taskApis.sendTaskEmail, payload);

    resolve(true);
  } catch (error) {
    reject(false);
  }
}

function* putTaskReply({ id, data, resolve, reject }) {
  try {
    let templateId = null;
    if (data.includeTemplateData) {
      let template = yield call(saveTemplate, {
        contactId: data.contact.id,
        description: data.content,
        content: data.content,
        type: 'clonedEmail',
        currentUserId: data.sender,
        taskId: data.task,
        name: data.templateName,
      });
      templateId = template.template.id;
      delete data.includeTemplateData;
      delete data.contact;
      delete data.task;
      delete data.templateName;
    }

    const payload = {
      ...data,
      sender: data?.sender,
      from: data?.from,
      template: templateId ? templateId : null,
    };
    const res = yield call(taskApis.putTaskEmail, id, payload);

    resolve(true);
  } catch (error) {
    reject(false);
  }
}

function* taskBulkUpdate({ tasks, payload, resolve, reject }) {
  try {
    const getCurrentPath = (state) => state.router?.location?.pathname;
    let path = yield select(getCurrentPath);
    let type = path.split('/');

    const data = tasks.map((task) => ({
      id: task.id,
      ...payload,
    }));
    yield call(taskApis.taskBulkUpdate, data);
    let tasksObj = yield select(getAllTasks);
    let outboxObj = yield select(getOutboxTasks);
    const boxType = yield select(getTaskBoxType);

    type = type[type.length - 1];
    const tab = yield select(getTaskCurrentTab);
    if (path === '/outbox') {
      yield put(
        taskActions.fetchOutboxTasks('', outboxObj.paging, outboxObj.filters, outboxObj.sort),
      );
    } else if (boxType === 'inbox' || boxType === 'outbox')
      yield put(
        taskActions.fetchTasks(tasksObj.paging, tasksObj.filters, tasksObj.sort, type, tab),
      );
    else if (boxType === 'errors') {
      const { paging, filters } = yield select(getAllErrors);
      yield put(taskActions.fetchTaskErrors(paging, filters, tab));
    }

    resolve(true);
  } catch (error) {
    reject(false);
  }
}

function* setTasksQueue({ ids }) {
  try {
    yield put(push(`/task/${ids[0]}`));
  } catch (error) {}
}

function* navigateNextTask({ view, resolve = () => {}, reject = () => {} }) {
  try {
    const queue = yield select(getTasksQueue);
    if (queue && queue.length) {
      const currentTask = yield select(getCurrentTask);
      const index = queue.findIndex((item) => item === currentTask.id);
      const nextTask = queue[index + 1];
      if (nextTask && nextTask !== '') {
        yield put(push(`/task/${nextTask}`));
        resolve(`/task/${nextTask}`);
      } else {
        yield put(taskActions.setTaskQueue([]));
        yield put(push(`/tasks`));
        resolve(`/tasks`);
      }
    } else {
      let tasks = [],
        task = {};
      if (view == 'errors') {
        tasks = yield select(getAllErrors);
        task = yield select(getCurrentError);
      } else {
        tasks = yield select(getAllTasks);
        task = yield select(getCurrentTask);
      }

      const currentIndex = tasks.data.findIndex((item) => item.id === task.id);
      let nextTask = {};

      if (currentIndex >= tasks?.paging?.perPage - 1) {
        const getCurrentPath = (state) => state.router?.location?.pathname;
        let path = yield select(getCurrentPath);
        let type = path.split('/');
        type = type[type.length - 1];
        const tab = yield select(getTaskCurrentTab);
        const nextPage = tasks?.paging?.pageNo + 1;
        yield put(
          taskActions.fetchTasks(
            { ...tasks.paging, pageNo: nextPage },
            tasks.filters,
            tasks.sort,
            type,
            tab,
            'taskView',
          ),
        );
      }
      nextTask = tasks.data[currentIndex + 1];

      let path = yield select(getCurrentPath);

      const actualIndex = currentIndex + tasks.paging.pageNo * tasks.paging.perPage + 1;
      if (actualIndex === tasks.paging.count) {
        yield put(replace('/tasks'));
        resolve('/tasks');
        // last step
      } else {
        if (nextTask && nextTask?.id) {
          if (view == 'errors') {
            yield put(taskActions.setTaskErrorDetails(nextTask));
          }
          path = path.substring(0, path.lastIndexOf('/') + 1);
          yield put(replace(`${path}${nextTask.id}`)); // next step
          resolve(`${path}${nextTask.id}`);
        }
      }
    }
  } catch (error) {
    reject(false);
  }
}

function* setTaskErrorDetails({ data }) {
  try {
    yield put(push(`/task/errors/${data.id}`));
  } catch (error) {}
}

function* fetchOutbox({ contactId, paging, filters, sort }) {
  const user = yield select(getCurrentUser);

  let dateRange = {};
  if (filters?.scheduledAt) {
    dateRange = yield call(getRelativeDateRange, filters?.scheduledAt);
  }
  delete filters?.scheduledAt;

  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      taskType_eq: 'selectEmailTemplate,email',
      // taskType_neq: 'selectEmailTemplate,email',
      // ...dateRange,
      ...(dateRange && !isEmpty(dateRange)
        ? {
            scheduledOn_gte: dateRange.start,
            scheduledOn_lte: dateRange.end,
          }
        : {}),
      ...(filters?.sequence
        ? {
            sequence_eq: filters.sequence.id,
          }
        : {}),
      ...(sort?.name ? { _sort: `${sort.name}:${sort.direction}` } : { _sort: `scheduledOn:desc` }),
      ...(filters?.user && filters?.user?.id
        ? {
            createdBy_eq: filters.user.id,
          }
        : {
            createdBy_eq: user.id,
          }),
      ...filters,
    };
    delete filter.pageNo;
    delete filter.perPage;
    delete filter.count;

    delete filter.scheduledAt;
    delete filter.user;
    delete filter.sequence;

    const response = yield call(taskApis.fetchOutbox, filter);
    yield put(taskActions.setOutbox(response.tasks, response.total));
  } catch (error) {
    yield put(taskActions.setOutbox([], { value: 0 }));
  }
}

function* deleteTask({ taskId, resolve, reject }) {
  try {
    const response = yield call(taskApis.deleteTask, taskId);
    toast.success('Task deleted successfully.');
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred please try again!');
    reject(false);
  }
}

function* fetchEnrolmentDrafts({ paging, filters, sort }) {
  try {
    let filter = {
      ...filters,
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.sequence
        ? {
            sequence_eq: filters?.sequence.id,
          }
        : {}),
      ...(filters?.error && !isEmpty(filters?.error)
        ? {
            error: filters?.error.value,
          }
        : {}),
    };

    if (filters?.dueDate && !isEmpty(filters?.dueDate)) {
      const dateRange = getRelativeDateRange(filters?.dueDate);
      filter = {
        ...filter,
        createdAt_gte: dateRange.start,
        createdAt_lte: dateRange.end,
      };
    }

    delete filter.dueDate;
    delete filter.sequence;

    const response = yield call(getDraftEnrollments, filter);
    yield put(taskActions.setEnrolmentDrafts(response.enrolments, response.total));
  } catch (error) {
    yield put(taskActions.setEnrolmentDrafts([], { value: 0 }));
  }
}

function* fetchInbox({ paging, filters, sort }) {
  const user = yield select(getCurrentUser);
  const inbox = yield select(getInbox);
  let dateRange = {};
  if (filters?.scheduledAt) {
    dateRange = yield call(getRelativeDateRange, filters?.scheduledAt);
  }
  delete filters?.scheduledAt;
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      taskType_eq: 'selectEmailTemplate,email',
      ...(dateRange && !isEmpty(dateRange)
        ? {
            repliedOn_gte: dateRange.start,
            repliedOn_lte: dateRange.end,
          }
        : {}),
      ...(filters?.sequence
        ? {
            sequence_eq: filters.sequence.id,
          }
        : {}),
      ...(sort?.name ? { _sort: `${sort.name}:${sort.direction}` } : {}),
      ...(filters?.user && filters?.user?.id
        ? {
            createdBy_eq: filters.user.id,
          }
        : {
            createdBy_eq: user.id,
          }),
      ...filters,
    };

    delete filter.scheduledAt;
    delete filter.user;
    delete filter.sequence;
    delete filter.pageNo;
    delete filter.perPage;
    delete filter.count;

    const response = yield call(taskApis.fetchInbox, filter);
    yield put(taskActions.setInbox(response.tasks, response.total));

    if (!inbox.data.length && response.tasks.length) {
      yield put(fetchContact(response.tasks[0].contact.id));
      yield put(fetchSequenceEnrollment(response.tasks[0].contact.id));
      yield put(taskActions.fetchTaskReplies(response.tasks[0].id));
    }
  } catch (error) {
    yield put(taskActions.setInbox([], { value: 0 }));
  }
}

function* fetchSent({ paging, filters, sort }) {
  const user = yield select(getCurrentUser);
  const sent = yield select(getSent);

  let dateRange = {};
  if (filters?.scheduledAt) {
    dateRange = yield call(getRelativeDateRange, filters?.scheduledAt);
  }
  delete filters?.scheduledAt;

  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      // ...dateRange,
      ...(dateRange && !isEmpty(dateRange)
        ? {
            sentOn_gte: dateRange.start,
            sentOn_lte: dateRange.end,
          }
        : {}),
      ...(filters?.sequence
        ? {
            sequence_eq: filters.sequence.id,
          }
        : {}),
      ...(sort?.name ? { _sort: `${sort.name}:${sort.direction}` } : {}),
      ...(filters?.user && filters?.user?.id
        ? {
            createdBy_eq: filters.user.id,
          }
        : {
            createdBy_eq: user.id,
          }),
      ...filters,
    };

    delete filter.scheduledAt;
    delete filter.user;
    delete filter.sequence;
    delete filter.pageNo;
    delete filter.perPage;
    delete filter.count;

    const response = yield call(taskApis.fetchSent, filter);
    yield put(taskActions.setSent(response.tasks, response.total));

    if (!sent.data.length && response.tasks.length) {
      yield put(fetchContact(response.tasks[0].contact.id));
      yield put(fetchSequenceEnrollment(response.tasks[0].contact.id));
      yield put(taskActions.fetchTaskReplies(response.tasks[0].id));
    }
  } catch (error) {
    yield put(taskActions.setSent([], { value: 0 }));
  }
}

function* deleteTaskReply({ id, resolve, reject }) {
  try {
    const messages = yield select(getEmailMessages);
    const res = yield call(taskApis.deleteTaskEmail, id);
    const newMessages = messages.data.filter((item) => item.id !== id);
    yield put(taskActions.setTaskReplies(newMessages, messages.paging.total - 1));
    toast.success('Message deleted!');
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

export function* watchSagas() {
  yield takeLatest(taskTypes.FETCH_TASKS, fetchTasks);
  yield takeLatest(taskTypes.FETCH_TASK_ERRORS, fetchTaskErrors);
  yield takeLatest(taskTypes.POST_TASK, postTask);
  yield takeLatest(taskTypes.PUT_TASK, putTask);
  yield takeLatest(taskTypes.FETCH_TASK_OPTIONS_FILTERS, fetchTaskFilterOptions);
  yield takeLatest(taskTypes.FETCH_TASK, fetchTask);
  yield takeLatest(taskTypes.FETCH_TASK_REPLIES, fetchTaskReplies);
  yield takeLatest(taskTypes.POST_TASK_REPLY, postTaskReply);
  yield takeLatest(taskTypes.PUT_TASK_REPLY, putTaskReply);
  yield takeLatest(taskTypes.TASK_BULK_UPDATE, taskBulkUpdate);
  yield takeLatest(taskTypes.NAVIGATE_NEXT_TASK, navigateNextTask);
  yield takeLatest(taskTypes.SET_TASK_ERROR_DETAILS, setTaskErrorDetails);
  yield takeLatest(taskTypes.FETCH_OUTBOX, fetchOutbox);
  yield takeLatest(taskTypes.DELETE_TASK, deleteTask);
  yield takeLatest(taskTypes.SET_TASKS_QUEUE, setTasksQueue);
  yield takeLatest(taskTypes.FETCH_ENROLMENT_DRAFTS, fetchEnrolmentDrafts);
  yield takeLatest(taskTypes.FETCH_INBOX, fetchInbox);
  yield takeLatest(taskTypes.FETCH_SENT, fetchSent);
  yield takeLatest(taskTypes.DELETE_TASK_REPLY, deleteTaskReply);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
