import { createContext, useState, useContext, useEffect } from 'react';
import api from 'src/api';
import {
  fetchEnrichSummary,
  fetchEnrichUserSummary,
  fetchEnrichCreditSummary,
  fetchEnrichCreditUsage,
  getEnrichConfig,
  putEnrichConfig,
  getEnrichCreditAllocation,
  createEnrichCreditAllocation,
  updateDefEnrichCreditAllocation,
  fetchEnrichWaterfall,
  putEnrichWaterfall,
  postEnrichWaterfall,
  putEnrichWaterfallService,
  resetEnrichWaterfall,
} from '../api/adminApi';
const dataProviderContext = createContext({});

import toast from 'src/utils/toast';
import { flatMap, isEmpty, map } from 'lodash';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

const defaultSummary = {
  cards: {
    personalEmails: 0,
    workEmails: 0,
    phone: 0,
    creditBalance: 0,
    creditUsed: 0,
    lastPurchase: 0,
  },
  thisMonth: {
    creditUsed: 0,
    creditAvailable: 0,
  },
  lastMonth: {
    creditUsed: 0,
    creditAvailable: 0,
  },
  credits: [],
  users: [],
};

export const useDataProviders = () => {
  const {
    summary,
    loading,
    config,
    fetchEnrichConfig,
    updateEnrichConfig,
    creditAllocation,
    fetchCreditAllocation,
    setDefaultCreditAllocation,
    defaultCreditLimit,
    postCreditAllocation,
    putCreditAllocation,
    deleteCreditAllocation,
    fetchWaterFall,
    createWaterFall,
    waterfall,
    updateWaterFall,
    resetWaterFall,
    setFilters,
    filters,
    waterfalls,
    selectedWaterfall,
    setSelectedWaterfall,
    selectWaterfall,
    updateWaterfallService,
    fetchSummary,
  } = useContext(dataProviderContext);
  return {
    summary,
    loading,
    config,
    fetchEnrichConfig,
    updateEnrichConfig,
    creditAllocation,
    fetchCreditAllocation,
    setDefaultCreditAllocation,
    defaultCreditLimit,
    postCreditAllocation,
    putCreditAllocation,
    deleteCreditAllocation,
    fetchWaterFall,
    createWaterFall,
    waterfall,
    updateWaterFall,
    resetWaterFall,
    setFilters,
    filters,
    waterfalls,
    selectedWaterfall,
    setSelectedWaterfall,
    selectWaterfall,
    updateWaterfallService,
    fetchSummary,
  };
};

export const DataProviderStore = ({ children }) => {
  const [summary, setSummary] = useState(defaultSummary);
  const [config, setConfig] = useState([]);
  const [creditAllocation, setCreditAllocation] = useState([]);
  const [defaultCreditLimit, setDefaultCreditLimit] = useState(0);
  const [waterfall, setWaterfall] = useState({});
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);

  const endDate = new Date();
  const [filters, setFilters] = useState({
    createdAt: {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    },
  });

  const [waterfalls, setWaterfalls] = useState([]);
  const [selectedWaterfall, setSelectedWaterfall] = useState({});

  const selectWaterfall = (waterfallId) => {
    setSelectedWaterfall(find(waterfalls, { id: waterfallId }));
  };

  // getEnrichCreditAllocation
  const [loading, setLoading] = useState({
    summary: true,
    cards: false,
    thisMonth: false,
    lastMonth: false,
    provider: false,
    users: false,
    waterfall: false,
  });

  useEffect(() => {
    fetchSummary();
  }, [filters]);

  const fetchCreditAllocation = async () => {
    try {
      const res = await getEnrichCreditAllocation();
      setCreditAllocation(res.users);
      setDefaultCreditLimit(res.userCreditLimit);
    } catch (error) {}
  };

  const setDefaultCreditAllocation = async (creditLimit) => {
    try {
      const res = await updateDefEnrichCreditAllocation({ creditLimit });
      await fetchCreditAllocation();
      return res;
    } catch (error) {}
  };

  const postCreditAllocation = async (data) => {
    try {
      const uid = data.user.id;
      const res = await createEnrichCreditAllocation(uid, {
        creditLimit: data.credit,
        beSpokeAllocation: true,
      });

      const existing = creditAllocation.filter((item) => item.user.id === uid);
      if (existing.length) {
        setCreditAllocation(
          creditAllocation.map((item) => {
            if (item.user.id === uid) {
              return {
                ...item,
                credits: data.credit,
              };
            }
            return item;
          }),
        );
      } else {
        setCreditAllocation([
          ...creditAllocation,
          {
            user: data.user,
            credits: data.credit,
          },
        ]);
      }
    } catch (error) {}
  };

  const deleteCreditAllocation = async (data) => {
    try {
      const uid = data.user.id;
      await createEnrichCreditAllocation(uid, { creditLimit: 0, beSpokeAllocation: false });
      setCreditAllocation(creditAllocation.filter((item) => item.user.id !== uid));
    } catch (error) {}
  };

  const putCreditAllocation = async (data) => {
    try {
      const uid = data.user.id;
      await createEnrichCreditAllocation(uid, {
        creditLimit: data.credit,
        beSpokeAllocation: true,
      });
      setCreditAllocation(
        creditAllocation.map((item) => {
          if (item.user.id === uid) {
            return {
              ...item,
              credits: data.credit,
            };
          }
          return item;
        }),
      );
    } catch (error) {}
  };

  const fetchEnrichConfig = async () => {
    try {
      const res = await getEnrichConfig();
      setConfig(res.config[0]);
    } catch (error) {}
  };

  const fetchWaterFall = async () => {
    try {
      setLoading({
        ...loading,
        waterfall: true,
      });
      const res = await fetchEnrichWaterfall();

      if (!selectedWaterfall?.id) setSelectedWaterfall(res?.waterfalls?.[0] || {});
      else
        setSelectedWaterfall(res?.waterfalls?.find(({ id }) => id === selectedWaterfall?.id) || {});

      const formatData = (key) => res?.waterfalls[0]?.[key] || [];

      setWaterfall({
        workEmailFlow: formatData('workEmailFlow'),
        personalEmailFlow: formatData('personalEmailFlow'),
        mobileFlow: formatData('mobileFlow'),
      });

      setWaterfalls(res.waterfalls);
      setLoading({
        ...loading,
        waterfall: false,
      });
    } catch (error) {
      setWaterfall({});
      setLoading({
        ...loading,
        waterfall: false,
      });
    }
  };

  const createWaterFall = async (data) => {
    try {
      const res = await postEnrichWaterfall(data);
      fetchWaterFall();
    } catch (error) {}
  };

  const updateWaterFall = async (id, data, isRefresh = true) => {
    try {
      const res = await putEnrichWaterfall(id, data);
      if (isRefresh) {
        fetchWaterFall();
      }
    } catch (error) {}
  };
  const resetWaterFall = async (id) => {
    try {
      const res = await resetEnrichWaterfall(id);
      fetchWaterFall();
    } catch (error) {}
  };
  const updateWaterfallService = async (id, data) => {
    try {
      const res = await putEnrichWaterfallService(id, data);
      fetchWaterFall();
    } catch (error) {}
  };

  const updateEnrichConfig = async (dataType, data) => {
    try {
      const payload = JSON.parse(JSON.stringify(config));
      payload[dataType] = data;
      delete payload.updatedAt;
      const res = await putEnrichConfig(config.id, payload);
      setConfig(res.config[0]);
    } catch (error) {}
  };

  const fetchSummary = async () => {
    try {
      let query = {};
      if (filters?.createdAt && !isEmpty(filters?.createdAt)) {
        query = {
          createdAt_lte: filters?.createdAt.endDate,
          createdAt_gte: filters?.createdAt.startDate,
        };
      }
      setLoading({
        ...loading,
        summary: true,
      });
      const promises = [];
      promises.push(fetchEnrichSummary(query));
      promises.push(fetchEnrichUserSummary(query));
      promises.push(fetchEnrichCreditSummary(query));
      promises.push(fetchEnrichCreditUsage(query));
      let response = await Promise.all(promises);
      setSummary({
        ...summary,
        cards: response[0],
        users: response[1],
        thisMonth: response[3].thisMonth,
        lastMonth: response[3].lastMonth,
        credits: response[2],
      });
      setLoading({
        ...loading,
        summary: false,
      });
    } catch (error) {
      setLoading({
        ...loading,
        summary: false,
      });
    }
  };

  return (
    <dataProviderContext.Provider
      value={{
        summary,
        loading,
        config,
        fetchEnrichConfig,
        updateEnrichConfig,
        creditAllocation,
        fetchCreditAllocation,
        setDefaultCreditAllocation,
        defaultCreditLimit,
        postCreditAllocation,
        putCreditAllocation,
        deleteCreditAllocation,
        waterfall,
        fetchWaterFall,
        updateWaterFall,
        resetWaterFall,
        createWaterFall,
        setFilters,
        filters,
        waterfalls,
        selectedWaterfall,
        selectWaterfall,
        updateWaterfallService,
        setSelectedWaterfall,
        fetchSummary,
      }}
    >
      {children}
    </dataProviderContext.Provider>
  );
};

export const withDataProviders = (Component) => (props) =>
  (
    <DataProviderStore>
      <Component {...props} />
    </DataProviderStore>
  );
