import React, { useEffect } from 'react';
import { Box, Card, CardContent, CircularProgress, Button } from '@mui/material';
import { useDashboardStore } from '../../../utils/dashboardStore';
import { ResponsiveContainer } from 'recharts';
import LineChartReport from '../../Board/Report/LineChart';
import ButtonGroup from '@mui/material/ButtonGroup';

const DashboardLineChart = () => {
  const LineChartButtonGroup = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Yearly', value: 'yearly' },
  ];
  const {
    loading,
    sequenceReport,
    setChartType,
    chartType,
    fetchSequenceReport,
    makeChartTypeDisable,
  } = useDashboardStore();

  const data = { data: sequenceReport?.sequenceSummary || [], tile: 'tasks' };

  const handleButtonChange = (name) => {
    setChartType(name);
  };

  useEffect(() => {
    if (chartType) {
      fetchSequenceReport();
    }
  }, [chartType]);

  return (
    <Card>
      <CardContent>
        {loading?.sequenceReport === true ? (
          <Box display="flex" justifyContent="center" alignItems="center" pt={4}>
            <CircularProgress size={26} style={{ marginBottom: 16 }} color="secondary" />
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', height: '28px' }}>
              <Box sx={{ fontWeight: 600, mt: 1 }}>Trend Analysis</Box>
              <ButtonGroup
                size="small"
                aria-label="Small button group"
                variant="outlined"
                sx={{ height: '28px' }}
              >
                {LineChartButtonGroup?.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      onClick={() => handleButtonChange(item?.value)}
                      disabled={makeChartTypeDisable?.includes(item?.value)}
                    >
                      <Box
                        sx={{ color: item?.value === chartType ? '#1976D2' : '#666', fontSize: 12 }}
                      >
                        {item?.label || ''}
                      </Box>
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>
            <LineChartReport
              data={data}
              sortOrder={['Enrolled', 'Contacted', 'Opened', 'Clicked', 'Replied', 'Interested']}
            />
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default DashboardLineChart;
