import React, { useEffect, useMemo } from 'react';
import { AppGrid } from 'src/components/App';
import { useDashboardStore } from '../../../utils/dashboardStore';
import { Typography } from '@mui/material';
import { useStyles } from './style';

function SequenceGrid() {
  const classes = useStyles();
  const { fetchUserStats, userStats, loading, filters } = useDashboardStore();
  const { data, paging, sort } = userStats;

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'User Name',
        options: {
          sort: true,
          setCellHeaderProps: (value) => ({
            style: { width: 10 },
          }),
          setCellProps: () => ({
            style: { width: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Typography color="secondary" variant="body2">
                {row?.fname || ''} {row?.lname || ''}
              </Typography>
            );
          },
        },
      },
      {
        name: 'prospectsEnrolled',
        label: 'Enrolled',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return row?.prospectsEnrolled;
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'contacted',
        label: 'Contacted',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return row?.prospectsContacted;
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'opened',
        label: 'Opened',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return row?.prospectsOpened;
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'clicked',
        label: 'Clicked',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return row?.prospectsClicked;
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'replied',
        label: 'Replied',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return row?.prospectsReplied;
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'interested',
        label: 'Interested',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return row?.prospectsInterested;
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
    ],
    [data],
  );

  useEffect(() => {
    fetchUserStats({ perPage: paging?.perPage, pageNo: 0 }, sort);
  }, [filters]);

  const onSort = (sort) => {
    fetchUserStats(paging, sort);
  };
  const handleTableChange = async ({ pageNo, perPage }) => {
    try {
      fetchUserStats(
        {
          pageNo,
          perPage,
        },
        sort,
      );
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  return (
    <AppGrid
      columns={columns.map((col, index) => ({
        ...col,
        options: columns?.columns?.length
          ? { ...col.options, ...columns?.columns[index] }
          : col.options,
      }))}
      skeletonLoading={true}
      onSort={onSort}
      onTableChange={handleTableChange}
      data={data}
      loading={loading.userStats}
      options={{
        sortOrder: sort,
        serverSide: true,
        pagination: true,
        page: paging.pageNo,
        rowsPerPage: paging.perPage,
        count: paging.count,
      }}
    />
  );
}

export default SequenceGrid;
