import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const checkUserVerificationStatus = (uid, tid) => {
  return api(`/user/verificationStatus?uid=${uid}&tid=${tid}`, null, 'get');
};

export const registerUser = (data) => {
  return api(`/user/register`, data, 'post');
};

export const registerUserWithThirdPartyServices = ({tempID, tenant}) => {
  return api(`/auth/callback/login?tempID=${tempID}&tenant=${tenant}`, null, 'get');
};

export const registerUserSetPassword = (uid, data) => {
  return api(`/user/updatepassword/${uid}`, data, 'put');
};

export const loginUser = (data) => {
  return api('/user/login', data, 'post');
};

export const getProfile = () => {
  return api('/user/me', null, 'get');
};

export const getUsers = () => {
  return api('/user', null, 'get');
};

export const forgotPassword = (payload) => {
  return api('/user/forgotpassword', payload, 'post');
};

export const resetPassword = (payload, token) => {
  let { userId, password } = payload;
  let data = { password: password };
  return api(`/user/updatepassword/${userId}`, data, 'put');
};

export const updateUser = (payload) => {
  return api(`/user/${payload.id}`, payload.data, 'put');
};

export const getUserById = (id) => {
  return api(`/user/${id}`, null, 'get');
};

export const fetchTeams = (teamsTotal) => {
  return api(`/team?status_eq=active&_size=${teamsTotal}`, null, 'get');
};

export const fetchOffices = (officeTotal) => {
  return api(`/office?status_eq=active&_size=${officeTotal}`, null, 'get');
};

export const setNlToken = (code, userid) => {
  return api(`/user/nltokanmaker?code=${code}&uid=${userid}`, null, 'get');
};

export const disconnectIntegration = (id, ststus) => {
  return api(`/user/nlrevoke`, null, 'get');
};

export const uploadImage = (file) => {
  return api(`/upload`, file, 'post');
};

export const fetchAdminDomainLookup = async (search) => {
  let res = [];
  const filters = {
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  res = await api(`/roleplay/domains${filter}`, null, 'get');
  return (res.domains || [])?.map(({ id, name }) => {
    return { label: name, value: id };
  });
};

export const fetchAdminIndustryLookup = async (search) => {
  let res = [];
  const filters = {
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  res = await api(`/roleplay/industry${filter}`, null, 'get');
  return (res.industries || [])?.map(({ id, name }) => {
    return { label: name, value: id };
  });
};

export const loginWithGoogle = (params = '') => {
  return api(`/auth/google${params}`, null, 'get');
};

export const getUserDataViaGoogleLogin = (params = '') => {
  return api(`/auth/callback/login?tempID=${params}`, null, 'get');
};

export const loginWithMicrosoft = (params = '') => {
  return api(`/auth/ms/login${params}`, null, 'get');
};
