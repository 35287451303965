import axios from 'axios';
import storage, { storageKey } from 'src/utils/storageUtils';
import { push, replace } from 'connected-react-router';
import cookieUtils from 'src/utils/cookieUtils';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const handleSuccess = (response) => {
  return response;
};

const handleError = (error) => {
  const errData = error?.response?.data || {};
  switch (error?.response?.status) {
    case 403:
      storage.del('TOKEN');
      storage.del('TENANT_HASH');
      replace('/auth/login');
      break;
    case 404:
      break;
    default:
      break;
  }
  return Promise.reject(errData);
};

const handleAuth = (config, isFormData) => {
  // const token = storage.get('TOKEN');
  // const tenantHash = storage.get('TENANT_HASH');

  const token = cookieUtils.get('AUTH_TOKEN');
  const tenantHash = cookieUtils.get('AUTH_TENANT_HASH');

  if (token && token !== '' && token !== null && typeof token !== 'undefined') {
    config.headers = {
      'Content-Type': isFormData
        ? 'application/x-www-form-urlencoded'
        : 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + token,
      Tenant: tenantHash,
    };
    // config.credentials = 'include';
  } else if (tenantHash && typeof tenantHash !== 'undefined') {
    config.headers = {
      'Content-Type': isFormData
        ? 'application/x-www-form-urlencoded'
        : 'application/json;charset=utf-8',
      Tenant: tenantHash,
    };
  }
  return config;
};

export default function api(path, payload, method, isFormData, onUploadProgress) {
  // client.interceptors.response.use(handleSuccess, handleError);
  client.interceptors.request.use(async (config) => {
    return handleAuth(config, isFormData);
  });

  return new Promise((resolve, reject) => {
    client
      .request({
        method: method,
        url: path,
        responseType: 'json',
        data: payload,
        ...(onUploadProgress && { onUploadProgress }),
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        const errData = error?.response?.data || {};
        return reject(errData);
      });
  });
}
