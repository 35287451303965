import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Box, Typography, InputAdornment, Input } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { cacheTest, checkUniqueUserEmail } from 'src/utils/validation';

import MailIcon from '@mui/icons-material/Mail';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ThirdPartyLoginButton } from '../styles';
import images from 'src/config/images';
import { loginWithGoogle, loginWithMicrosoft } from '../../api/authApis';
import toast from 'src/utils/toast';

const RegistrationForm = ({ onSubmit }) => {
  const [loading, setLoading] = React.useState(false);
  const emailUniqueTest = useRef(cacheTest(checkUniqueUserEmail));

  const handleGoogleLogin = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const referral = params.get('referral');
      const res = await loginWithGoogle(referral ? `?referral=${referral}` : '');
      window.location.replace(res);
    } catch (e) {
      toast.error('Sign up with google is Failed please try again');
    }
  };

  const handleMicroSoftLogin = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const referral = params.get('referral');
      const res = await loginWithMicrosoft(referral ? `?referral=${referral}` : '');
      window.location.replace(res);
    } catch (e) {
      toast.error('Sign up with microsoft is Failed please try again');
    }
  };

  return (
    <Form
      initialValues={{
        email: '',
        terms: false,
      }}
      validationSchema={Yup.object().shape({
        terms: Yup.boolean().oneOf([true], 'Please agree to terms and conditions.'),
        email: Yup.string()
          .required('Email is required!')
          .email('Please enter a valid email address.')
          .test(
            'username-backend-validation',
            'This email is already taken!',
            async (value, { createError }) => {
              return emailUniqueTest.current(value, {
                createError,
                skipAllowed: false,
              });
            },
          ),
      })}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
          >
            <Form.Field.Input
              fullWidth
              variant="outlined"
              name="email"
              placeholder="Email"
              disabled={loading}
              autoComplete="on"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon style={{ fill: '#d1d1d1' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Spacer x={2} y={2} />
            <Form.Field.Checkbox
              name="terms"
              label={
                <Typography variant="body2" component="span">
                  Get emails about product updates, industry news, and events. If you change your
                  mind, you can unsubscribe at any time. I accept{' '}
                  <a href="http://superreach.com/terms-of-service.html" target="_blank">
                    <Typography variant="body2" color="secondary" component="span">
                      Privacy Policy and Terms
                    </Typography>
                    .
                  </a>
                </Typography>
              }
            />
            <Spacer x={1} y={1} />

            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
                fullWidth
                size="large"
                endIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
            </Box>
            {/*<Box mt={3} mb={3} justifyContent="space-between" display="flex" flexDirection="row">*/}
            {/*  <ThirdPartyLoginButton*/}
            {/*      sx={{padding:'6px 13px !important'}}*/}
            {/*    startIcon={<img src={images.icons.GoogleIcon} alt="Google" />}*/}
            {/*    onClick={() => handleGoogleLogin()}*/}
            {/*  >*/}
            {/*    Sign up with Google*/}
            {/*  </ThirdPartyLoginButton>*/}
            {/*  <ThirdPartyLoginButton*/}
            {/*      sx={{padding:'6px 13px !important'}}*/}
            {/*    onClick={() => handleMicroSoftLogin()}*/}
            {/*    startIcon={<img src={images.icons.microSoftIcon} alt="Microsoft" />}*/}
            {/*  >*/}
            {/*    Sign up with Microsoft*/}
            {/*  </ThirdPartyLoginButton>*/}
            {/*</Box>*/}
            <Box
              mt={3}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box mt={1} display="flex" justifyContent="center">
                <Typography color="textSecondary" variant="body2">
                  Already have an account?{' '}
                  <Link to="/auth/login">
                    <Typography color="secondary" variant="body2" display="inline" component="span">
                      Log in
                    </Typography>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default RegistrationForm;
