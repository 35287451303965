import React, { useMemo, useState } from 'react';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { useContactsColumns } from './useContactsColumns';
import ContactActionDrawer from './ContactActionDrawer';
import { Button } from 'src/components/shared';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { contactBulkActions, contactStatusList } from '../../config';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { bulkAddToCrmContacts, fetchSeqStatus } from '../../api/contactApis';
import toast from 'src/utils/toast';
import InfoIcon from '@mui/icons-material/Info';
import images from '../../../../config/images';
import ContactFilterDrawer from './ContactFilterDrawer';
import {countSelectedAdvanceFilters} from '../../utils/contactUtils';
import { useContactFilterStore, withContactGridProvider } from './useContactFilter';

const maxLengthForSequenceAction = 15;

function ContactsGrid({ fields, contacts, edit, drawerToggle, loading, integrations, ...props }) {
  const { data: contactsData, filters, paging, sort } = contacts;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [bulkAction, setBulkAction] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const { handleContactFilters, setHandleContactFilters, onClearFilters } = useContactFilterStore();
  const appliedFilters = countSelectedAdvanceFilters(handleContactFilters);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const contacts = await Promise.all(
        contactsData?.map(async (contact) => {
          if (!contact?.id) return {};
          const sequenceStatus = await fetchSeqStatus(contact?.id);
          return {
            ...contact,
            status: sequenceStatus?.status?.enrolmentStatus || 'never',
          };
        }),
      );
      setData(contacts);
      setIsLoading(false);
    };
    fetchData();
  }, [contactsData, filters, paging, sort]);

  React.useEffect(() => {
    if (rowsSelected?.length > maxLengthForSequenceAction) setShowAlertMessage(true);
  }, [rowsSelected]);

  const selectedContacts = useMemo(() => {
    return data.filter((item, index) => rowsSelected.includes(index));
  }, [rowsSelected, data]);

  const isIncludeUnsubscribedUser = selectedContacts?.find(
    (user) => user.status === 'unsubscribed',
  );

  const onDeleteContact = async (contact) => {
    const response = await props.deleteContact(contact.id);
    if (!response?.error) {
      props.fetchContacts(paging, filters, sort);
    }
  };

  const contactFilters = [
    {
      key: 'enrolledBy',
      title: 'Enrolled by',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      // isDisabled: !(isEmpty(filters) || filters.enrolledBy),
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'status_eq',
      title: 'Status',
      type: 'dropdown',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      // isDisabled: !(isEmpty(filters) || filters.status_eq),
      options: contactStatusList,
    },
    {
      key: 'createdAtRange',
      title: 'Created date',
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      // isDisabled: !(isEmpty(filters) || filters.createdAtRange),
      isPrimary: true,
    },
    {
      key: 'lastActivityDate',
      title: 'Last Activity',
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      // isDisabled: !(isEmpty(filters) || filters.lastActivityDate),
      isPrimary: true,
    },
    {
      key: 'status_eq',
      title: 'Filters',
      type: 'button',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      padding: appliedFilters ? '4px 8px' : '',
      options: contactStatusList,
      endIcon: (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ fontSize: '14px !important', gap: '5px', color: '#1976D2' }}
        >
          <img src={images.sequence.FilterIcon} alt="" />
          {appliedFilters ? appliedFilters : ''}
        </Box>
      ),
      handleClick: () => setHandleContactFilters({ ...handleContactFilters, open: true }),
    },
    {
      key: 'status_eq',
      title: 'Clear All',
      type: 'button',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      // isDisabled: !(isEmpty(filters) || filters.status_eq),
      options: contactStatusList,
      handleClick: () => onClearFilters(),
    },
  ];

  const contactsColumns = useContactsColumns(
    data,
    fields,
    edit,
    drawerToggle,
    props,
    onDeleteContact,
  );

  const handlePerPageChange = (data1, data2) => {};

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchContacts(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
    );
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchContacts(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          sort,
        );
      } else {
        props.fetchContacts({ ...paging, pageNo: 0 }, { ...filters, [key]: value }, sort);
      }
    } else {
      delete filters[key];
      props.fetchContacts({ ...paging, pageNo: 0 }, filters, sort);
    }
  };

  const handleColumnChange = (data1, data2) => {};

  const onBulkAction = (key) => {
    setBulkAction(key);
  };

  const closeBulkAction = (key) => {
    setBulkAction('');
    setRowsSelected([]);
    setSelectedContactIds([]);
  };

  const onSort = (newSort) => {
    props.fetchContacts(paging, filters, newSort);
  };

  const bulkDeleteContacts = async () => {
    try {
      setIsDeleting(true);
      await props.contactBulkAction(bulkAction, selectedContacts, {});
      setIsDeleting(false);
      closeBulkAction();
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const bulkActions = useMemo(() => {
    let filteredBulkActions = contactBulkActions;
    if (props.currentUser?.acl?.accessLevel !== 'Admin')
      filteredBulkActions = contactBulkActions.filter((action) => action.key !== 'delete');
    return filteredBulkActions?.flatMap((action) => {
      if (action.key === 'addtocrm') {
        const isBullhornActive = () => {
          const bullhornIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'bullhorn',
          );
          return (
            bullhornIntegration?.status === 'active' &&
            !bullhornIntegration?.errorMessage &&
            !bullhornIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !bullhornIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isJobAdderActive = () => {
          const jobadderIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'jobadder',
          );
          return (
            jobadderIntegration?.status === 'active' &&
            !jobadderIntegration?.errorMessage &&
            !jobadderIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !jobadderIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isVincereActive = () => {
          const vincereIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'vincere',
          );
          return (
            vincereIntegration?.status === 'active' &&
            !vincereIntegration?.errorMessage &&
            !vincereIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !vincereIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isSalesforceActive = () => {
          const salesforceIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'salesforce',
          );
          return (
            salesforceIntegration?.status === 'active' &&
            !salesforceIntegration?.errorMessage &&
            !salesforceIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !salesforceIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isHubspotActive = () => {
          const hubspotIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'hubspot',
          );
          return (
            hubspotIntegration?.status === 'active' &&
            !hubspotIntegration?.errorMessage &&
            !hubspotIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !hubspotIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isAnyCRMActive =
          isBullhornActive() ||
          isJobAdderActive() ||
          isVincereActive() ||
          isSalesforceActive() ||
          isHubspotActive();
        const options = [
          ...(!isHubspotActive() && !isSalesforceActive()
            ? [
                {
                  label: 'Candidate',
                  value: 'candidate',
                },
              ]
            : []),
          {
            label: 'Contact',
            value: 'contact',
          },
          ...(isBullhornActive()
            ? [
                {
                  label: 'Lead',
                  value: 'lead',
                },
              ]
            : []),
        ];
        const callBackFunc = async (val) => {
          closeBulkAction();
          if (val) {
            const res = await bulkAddToCrmContacts(selectedContactIds, val?.value);
            if (res.data)
              toast.success(
                `Your request to add ${selectedContactIds.length} contacts to CRM has been submitted. Please check for successful addition after a few minutes.`,
                'tc',
              );
          }
        };
        if (isAnyCRMActive)
          return {
            ...action,
            ...(options?.length === 1
              ? {
                  onClick: () => callBackFunc(options?.[0]),
                }
              : {
                  onChange: callBackFunc,
                  options: options,
                  optLabel: 'label',
                  optValue: 'value',
                  type: 'dropdown',
                }),
          };
        else return [];
      }
      if (action.key === 'addToSequence')
        return {
          ...action,
          ...(rowsSelected?.length > maxLengthForSequenceAction ? { disabled: true } : {}),
        };
      return action;
    });
  }, [contactBulkActions, props.currentUser, rowsSelected, integrations]);

  return (
    <>
      {showAlertMessage && rowsSelected?.length > maxLengthForSequenceAction && (
        <Alert
          severity="info"
          sx={{
            '& svg': {
              color: 'white',
            },
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.17px',
            background: '#1976D2',
            color: 'white',
            margin: '16px 0',
          }}
          onClose={() => setShowAlertMessage(false)}
        >
          For optimum performance, multiple enrolments are limited to 15 prospects at a time. Please
          bear with us whilst further optimisations are being made.
        </Alert>
      )}
      <AppGrid
        selectableRows="multiple"
        columns={contactsColumns.map((col, index) => ({
          ...col,
          options: contactsColumns?.columns?.length
            ? { ...col.options, ...contactsColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableRowPerPageChange={handlePerPageChange}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        onClearFilters={onClearFilters}
        onColumnChange={() => handleColumnChange(false)}
        loading={loading || isLoading}
        filters={contactFilters}
        appliedFilters={filters}
        bulkActions={bulkActions}
        onBulkAction={onBulkAction}
        onSort={onSort}
        options={{
          ...(!isEmpty(sort) && {
            sortOrder: {
              name: sort?.name || 'none',
              direction: sort?.direction || 'none',
            },
          }),
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search contacts',
          searchText: filters?._search ? filters._search : '',
          search: true,
          setRowProps: (_, dataIndex) => {
            return data[dataIndex]?.status === 'unsubscribed'
              ? { style: { background: '#F4F4F4', opacity: 0.6 } }
              : {};
          },
          onRowSelectionChange: (_, all) => {
            setRowsSelected(
              all.flatMap((row) =>
                data[row.dataIndex]?.status === 'unsubscribed' ? [] : row.dataIndex,
              ),
            );
            setSelectedContactIds(
              all?.reduce((acc, { index }) => {
                if (data[index]?.id && data[index]?.status !== 'unsubscribed')
                  acc?.push(data[index]?.id);
                return acc;
              }, []),
            );
          },
          rowsSelected: rowsSelected,
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                // startIcon={<AddIcon />}
                onClick={() => {
                  props.toggleAddContactForm();
                }}
                disableElevation
              >
                <Typography variant="body2">Create contact</Typography>
              </Button>
            );
          },
        }}
      />
      <ContactActionDrawer
        action={
          !['addToSequence', 'delete'].includes(bulkAction) ||
          (!isIncludeUnsubscribedUser && ['addToSequence'].includes(bulkAction))
            ? bulkAction
            : false
        }
        onClose={closeBulkAction}
        contactBulkAction={props.contactBulkAction}
        selectedContacts={selectedContacts}
      />
      <ContactFilterDrawer
      />
      <ConfirmDialog
        title={
          isIncludeUnsubscribedUser && ['addToSequence'].includes(bulkAction)
            ? 'Add to sequence'
            : ['delete'].includes(bulkAction)
            ? 'Delete Contact(s)'
            : ''
        }
        showCancelButton={!isIncludeUnsubscribedUser}
        buttonTitle={
          isIncludeUnsubscribedUser && ['addToSequence'].includes(bulkAction)
            ? 'Remove unsubscribed user(s)'
            : isIncludeUnsubscribedUser && ['delete'].includes(bulkAction)
            ? 'Cancel'
            : ['delete'].includes(bulkAction)
            ? 'Delete'
            : ''
        }
        confirmButtonVariant={'contained'}
        open={
          (isIncludeUnsubscribedUser && ['addToSequence'].includes(bulkAction)) ||
          ['delete'].includes(bulkAction)
        }
        onClose={closeBulkAction}
        onConfirm={() => (isIncludeUnsubscribedUser ? closeBulkAction() : bulkDeleteContacts())}
        loading={isDeleting}
      >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          {isIncludeUnsubscribedUser ? (
            <Stack direction={'row'} gap={'12px'}>
              <InfoIcon style={{ fill: '#EF5350' }} />
              <Typography variant="body1" color="danger">
                {['addToSequence'].includes(bulkAction)
                  ? 'Your selection contains unsubscribed contacts. Please remove them to continue with enrollment.'
                  : ['delete'].includes(bulkAction)
                  ? 'Unsubscribed users cannot be deleted to ensure your outreach stays within deliverability standards and thresholds.Are you sure you want to delete selected contacts.'
                  : ''}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Are you sure you want to delete selected contacts.
            </Typography>
          )}
        </Box>
      </ConfirmDialog>
    </>
  );
}
export default React.memo(withContactGridProvider(ContactsGrid));
